
import { getTheme } from "../includes/themesFunc"
import { CircularProgress, Box, Stack, Typography, Button, CssBaseline, useMediaQuery, Card, CardHeader, CardContent, List, ListItem, ListItemText, Drawer, Divider, ListItemButton, Avatar, BottomNavigation, BottomNavigationAction, AppBar, Toolbar, Menu, MenuItem, Container, Link } from "@mui/material"
import { createTheme, ThemeProvider, styled } from "@mui/material/styles"
import { Global } from '@emotion/react';
import { useMemo, useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { LanguageContext } from '../components/LanguageProvider'
import { getMydriveidDossier } from "../includes/dbDossiersFunc"
import OrderDrawer from "../components/OrderDrawer"
import { grey } from '@mui/material/colors';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { getRootPath } from '../includes/coreFunc'



const drawerBleeding = 55

const GreyBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? grey[100] : grey[800],
}));


const Mydriveid = () => {
    const { mydriveid } = useParams()
    const { language, languages, changeLanguage, getLabel} = useContext(LanguageContext)
    const prefersDark = useMediaQuery('(prefers-color-scheme: dark)')
    const [ colorScheme, setColorScheme ] = useState(prefersDark? 'dark' : 'light')
    const [ design, setDesign ] = useState( {} )
    const theme = useMemo(() => createTheme( design ),[ design ])
    const onMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [ actionMode, setActionMode ] = useState('')
    const [ openActionDrawer, setOpenActionDrawer ] = useState(false)
    const [ dossier, setDossier] = useState(null)
    const [ pending, setPending ] = useState(true)
    const [ unknown, setUnknown ] = useState(false)
    const [ drawerOpen, setDrawerOpen ] = useState(false);
    const [ mode, setMode ] = useState('');
    const [ languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
    const [rootPath, setRootPath] = useState('')
    const navigate = useNavigate()


    useEffect(() => {
        (async () => {
            if(language && mydriveid){
                const path = await getRootPath()
                setRootPath(path)
                setUnknown(false)
                setPending(true)
                const answer = await getMydriveidDossier(mydriveid, language.id)
                if(answer.status === 1 && answer.count > 0){
                    if(answer.results.brand_usestyle === 1){
                        if(document.getElementById('brand-style').href !== path + answer.results.brand){
                            document.getElementById('brand-style').href = path + answer.results.brand
                        }
                    }else{
                        document.getElementById('brand-style').href = ''
                    }

                    //const design = await getTheme(answer.results.brand_name.toLowerCase(), colorScheme === 'dark')
                    await handleDesign(answer.results.brand_name.toLowerCase(), colorScheme === 'dark')

console.log(answer)

                    setDossier(answer.results)
                    setPending(false)
                }else{
                    setUnknown(true)
                    setPending(false)
                }                
            }
        })();
    // eslint-disable-next-line
    },[ mydriveid, language ])


    //Wenn der Benutzer im System seine Darstellung auf dark/light umstellt.
    useEffect(() => {
        (async () => {
            const newColorScheme = prefersDark ? 'dark' : 'light'
            setColorScheme(newColorScheme)
            if(dossier){
                //const design = await getTheme(dossier.brand_name, prefersDark)
                await handleDesign( dossier.brand_name, prefersDark )
            }
        })();
    // eslint-disable-next-line         
    },[ prefersDark ])

    
    const handleColorScheme = async ( colorScheme ) => {
        setColorScheme( colorScheme )
        //const design = await getTheme(dossier.brand_name, colorScheme === 'dark')
        await handleDesign( dossier.brand_name, colorScheme === 'dark' )
    }


    const handleDesign = async (name, prefersDark) => {

        const design = await getTheme(name, prefersDark)


        if(prefersDark){
            if(!design.palette){
                design.palette = {mode: 'dark'}
            }else if(!design.palette.mode || design.palette.mode !== 'dark'){
                design.palette.mode = 'dark'
            }
        }

        setDesign( design )
    }


    //Drawer für eine Action (quote/order) anzeigen.
    const handleStartAction = (mode) => {
        setActionMode(mode)
        setOpenActionDrawer(true)
    }

    const toggleDrawer = (state, mode) => {
        setMode(mode)
        setDrawerOpen(state)
    }


    return ( 
        <div className="mydriveid">
            <Global
                styles={{
                '.details-drawer > .MuiPaper-root': {
                    height: `calc(70% - ${drawerBleeding}px)`,
                    overflow: 'visible',
                },
                '.order-drawer > .MuiPaper-root': {
                    height: '80%',
                },
                }}
            />

            <ThemeProvider theme={ theme }>
                <CssBaseline />
                { unknown && 
                    <div className="unknown">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Box  display='flex' flexDirection='column' justifyContent="center" alignItems="center">
                                <img style={{maxWidth: '300px'}} src={rootPath + "/assets/images/404_notfound.jpg"} alt="not found" />
                                <Typography variant="h1">404</Typography>
                                <Typography >not found</Typography>
                                <Button onClick={() => navigate('/')}>Search</Button>
                            </Box>
                        </Box>
                    </div>
                }
                { (pending && !unknown) &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh"
                    >
                        <Stack justifyContent="center" alignItems="center">
                            <CircularProgress/>
                            <Typography>loading</Typography>
                        </Stack>
                    </Box>
                }
                { (!pending && !unknown) &&
                    <div className="dossier"  style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                        { onMobile ? (
                        <>
                            <Stack direction='row' display='flex' spacing={0} m={1}>
                                <Typography variant="h5" color='primary'>{ dossier.mydriveid }</Typography>
                                <Box sx={{ flex: '1 1 auto' }} />
                                { dossier.logoimage_path && 
                                <>
                                    { (colorScheme === 'dark' && dossier.logoimagedark_path) ? (
                                        <img src={ dossier.logoimagedark_path } alt={ dossier.logoimage_alt } style={{maxWidth: '100px', maxHeight: '25px'}}/>
                                    ) : (
                                        <img src={ dossier.logoimage_path } alt={ dossier.logoimage_alt } style={{maxWidth: '100px', maxHeight: '25px'}}/>
                                    )}
                                </>
                                }
                            </Stack>
                            {/* <Stack direction='column' spacing={3} sx={{pb: drawerBleeding + 5 + 'px', pl:1, pr:1}}> */}
                            <Box pl={1} pr={1} mt={2} sx={{display: 'flex', flexDirection:'column', alignItems: 'stretch', flexGrow: 1}}>
                                {dossier.blocks.map((block) => (
                                    <Card className="block-mobile" key={ block.id } sx={{mb: 2}}>
                                        <CardHeader
                                            sx={{p: 1}}
                                            avatar={
                                                (colorScheme === 'dark' && block.imagedark_path) ? (
                                                    <img src={ block.imagedark_path } alt={ block.image_alt } style={{maxWidth: '80px', maxHeight: '50px'}} />
                                                ) : (
                                                    <img src={  block.image_path } alt={ block.image_alt } style={{maxWidth: '80px', maxHeight: '50px'}} />
                                                )
                                            }
                                            title={ block.title }
                                            titleTypographyProps={{variant: 'h6', color: 'primary'}}
                                            subheader={ block.description }
                                        />
                                        <CardContent>
                                            {Object.entries(block.entries).map(([group, entries], index) =>(
                                                <div className="group" key={ index }>
                                                    <Typography variant="subtitle1">{ group }</Typography>
                                                    <List dense>
                                                        {entries.map((entry, index) => (
                                                            <ListItem key={ index } sx={{padding: '0 0 0 5px'}}>
                                                                <Typography variant="subtitle2" component='div' color="text.secondary" >{ entry.caption }</Typography>
                                                                <Box sx={{ flex: '1 1 auto' }} />
                                                                { entry.link ? (
                                                                    <Link variant="body2" target='blank' href={ entry.link }>{ entry.content }</Link>
                                                                ) : (
                                                                    <Typography variant="body2">{ entry.content }</Typography>
                                                                )}
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </div>                                            
                                            ))}                                        
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>

                            <Drawer
                                className="details-drawer"
                                anchor="bottom"
                                open={drawerOpen}
                                onClose={() => toggleDrawer(false)}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                <GreyBox
                                    className="box-grey"
                                    sx={{
                                        position: 'absolute',
                                        top: -drawerBleeding,
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        visibility: 'visible',
                                        right: 0,
                                        left: 0,
                                    }}
                                >
                                    <BottomNavigation
                                        showLabels={true}
                                        sx={{backgroundColor: 'transparent'}}
                                    >
                                        <BottomNavigationAction onClick={() => toggleDrawer(true, 'language')} label={<span style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{ language.name }</span>} icon={ <Typography sx={{fontWeight: 'bolder'}}>{ language.symbol }</Typography> }/>
                                        {dossier && dossier.hideorder === 0 && <BottomNavigationAction onClick={() => handleStartAction('order')} label={ getLabel('key_doorder') } icon={<ShoppingCartIcon/> }/>}
                                        {dossier && dossier.hidequote === 0 && <BottomNavigationAction onClick={() => handleStartAction('quote')} label={ getLabel('key_getquote') } icon={<RequestQuoteIcon/> }/>}
                                        <BottomNavigationAction onClick={() => toggleDrawer(true, 'details')} label='Details' icon={<InfoIcon/> }/>

                                    </BottomNavigation>
 
                                </GreyBox>
                                <GreyBox
                                    className="box-grey"
                                    sx={{
                                    px: 2,
                                    pb: 2,
                                    height: '100%',
                                    overflow: 'auto',
                                    }}
                                >
                                    { mode === 'details' && 
                                        <>
                                        { dossier && dossier.hidedetails === 0 &&
                                            <List>
                                                <ListItem><ListItemText primary={ dossier.mydriveid } secondary='id'/></ListItem>
                                                { dossier.part && 
                                                    <ListItem><ListItemText primary={ dossier.part } secondary={getLabel('key_part')}/></ListItem>
                                                }
                                                <ListItem><ListItemText primary={ dossier.ordernumber } secondary={getLabel('key_ordernumber')}/></ListItem>
                                                <ListItem><ListItemText primary={ dossier.quantity } secondary={getLabel('key_quantity')}/></ListItem>
                                                { dossier.deliverydate &&
                                                    <ListItem><ListItemText primary={ dossier.deliverydate } secondary={getLabel('key_date')}/></ListItem>
                                                }
                                            </List>
                                        }
                                        </>
                                    }
                                    { mode === 'language' && 
                                        <List sx={{mt: 3}} >
                                            {languages && languages.map((lang, index) => (
                                                <ListItem key={ index } disablePadding>
                                                    <ListItemButton onClick={() => {
                                                        toggleDrawer(false)
                                                        changeLanguage(lang)
                                                    }}>
                                                        <Avatar sx={{mr: 5}}>{ lang.symbol }</Avatar>
                                                        <ListItemText primary={ lang.name }/>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    }
                                    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                                        <BottomNavigation>
                                            <BottomNavigationAction label="Mode" icon={ colorScheme === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/> }
                                                onClick={() => handleColorScheme( colorScheme === 'dark' ? 'light' : 'dark' )}
                                            />
                                            <BottomNavigationAction label="Mode" icon={<CloseIcon />}
                                                onClick={() => toggleDrawer(false)}
                                            />
                                        </BottomNavigation>
                                    </Box>
                                </GreyBox>
                            </Drawer>

                        </>
                        ) : (
                        <> {/* Wenn nicht auf einem Mobile */}
                            <AppBar position="static" sx={{zIndex: 99}} enableColorOnDark>
                                <Toolbar>
                                    { dossier.logoimagecontrast_path &&
                                        <>
                                            { (colorScheme === 'dark' && dossier.logoimagecontrastdark_path) ? (
                                                <img src={ dossier.logoimagecontrastdark_path } alt={ dossier.logoimagecontrast_alt } style={{maxWidth: '150px', maxHeight: '25px'}}/>
                                            ) : (
                                                <img src={ dossier.logoimagecontrast_path } alt={ dossier.logoimagecontrast_alt } style={{maxWidth: '150px', maxHeight: '25px'}}/>
                                            )}
                                        </>
                                    }
                                    { (!dossier.logoimagecontrast_path && dossier.logoimage_path) &&
                                        <img src={ dossier.logoimage_path } alt={ dossier.logoimage_alt } style={{maxWidth: '150px', maxHeight: '25px'}}/>
                                    }
                                    <Typography variant="h5" component='div' sx={{ml: 5, fontWeight: 'bolder', flexGrow: 1}} >{ dossier.mydriveid }</Typography>
                                    
                                    <Stack alignItems='center' sx={{cursor: 'pointer', ml: 2}} onClick={() => handleColorScheme( colorScheme === 'dark' ? 'light' : 'dark' )}>
                                        { colorScheme === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/> }
                                        <Typography variant="subtitle2"
                                        sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>Mode</Typography>
                                    </Stack>
                                    {dossier && dossier.hideorder === 0 &&
                                        <Stack alignItems='center' onClick={() => handleStartAction('order')} sx={{cursor: 'pointer', ml: 3}}>
                                            <ShoppingCartIcon/>
                                            <Typography variant="subtitle2"
                                            sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ getLabel('key_doorder') }</Typography>
                                        </Stack>
                                    }
                                    {dossier && dossier.hidequote === 0 &&
                                        <Stack alignItems='center' onClick={() => handleStartAction('quote')} sx={{cursor: 'pointer', ml: 2}}>
                                            <RequestQuoteIcon/>
                                            <Typography variant="subtitle2"
                                            sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ getLabel('key_getquote') }</Typography>
                                        </Stack>
                                    }
                                    <Divider orientation="vertical" flexItem sx={{backgroundColor: theme.palette.primary.contrastText, ml:2}}/>
                                    <Stack alignItems='center' onClick={(e) => setLanguageMenuAnchor(e.currentTarget) } sx={{cursor: 'pointer', ml: 2}}>
                                        <Typography variant="body1" sx={{fontWeight: 'bolder'}}>{ language.symbol }</Typography>
                                        <Typography variant="subtitle2"
                                        sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ language.name }</Typography>
                                    </Stack>
                                    <Menu
                                        anchorEl={ languageMenuAnchor }
                                        open={ Boolean(languageMenuAnchor) }
                                        onClose={() => setLanguageMenuAnchor(null)}
                                    >
                                            {languages && languages.map((lang, index) => (
                                                <MenuItem key={ index } onClick={() => {
                                                    changeLanguage(lang)
                                                    setLanguageMenuAnchor(null)
                                                }}>{ lang.name }</MenuItem>
                                            ))}
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            { dossier && dossier.hidedetails === 0 &&
                                <GreyBox className="box-grey" sx={{pt: 2, pb: 1}}>
                                    {/* <Container fixed>
                                        <Stack direction='row' flexWrap='wrap'> */}
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '30px'}}>
                                            <ListItem sx={{p:0, width: 'unset'}}><ListItemText primary={ dossier.mydriveid } secondary='id'/></ListItem>
                                            { dossier.part && 
                                                    <ListItem sx={{p:0, width: 'unset'}}><ListItemText primary={ dossier.part } secondary={getLabel('key_part')}/></ListItem>
                                            }                                            
                                            <ListItem sx={{p:0, width: 'unset'}}><ListItemText primary={ dossier.ordernumber } secondary={getLabel('key_ordernumber')}/></ListItem>
                                            <ListItem sx={{p:0, width: 'unset'}}><ListItemText primary={ dossier.quantity } secondary={getLabel('key_quantity')}/></ListItem>
                                            { dossier.deliverydate &&
                                                    <ListItem sx={{p:0, width: 'unset'}}><ListItemText primary={ dossier.deliverydate } secondary={getLabel('key_date')}/></ListItem>
                                            }
                                        </div>
                                        </div>
                                        {/* </Stack>
                                    </Container> */}
                                </GreyBox>
                            }

                            <Box sx={{display: 'flex', flexDirection:'row', flexWrap: 'wrap', alignSelf: 'center', alignItems: 'flex-start', alignContent: 'flex-start', flexGrow: 1}}>
                                {dossier.blocks.map((block) => (
                                    <Card className="block-desktop" key={ block.id } sx={{minWidth: '400px', m: 1}}>
                                        <CardHeader
                                            sx={{p: 1}}
                                            avatar={
                                                (colorScheme === 'dark' && block.imagedark_path) ? (
                                                    <img src={ block.imagedark_path } alt={ block.image_alt } style={{maxWidth: '80px', maxHeight: '50px'}} />
                                                ) : (
                                                    <img src={ block.image_path } alt={ block.image_alt } style={{maxWidth: '80px', maxHeight: '50px'}} />
                                                )
                                            }
                                            title={ block.title }
                                            titleTypographyProps={{variant: 'h6', color: 'primary'}}
                                            subheader={ block.description }
                                        />
                                        <CardContent>
                                            {Object.entries(block.entries).map(([group, entries], index) =>(
                                                <div className="group" key={ index }>
                                                    <Typography variant="subtitle1" mt={1}>{ group }</Typography>
                                                    <List dense sx={{padding: 0}}>
                                                        {entries.map((entry, index) => (
                                                            <ListItem key={ index } sx={{padding: '0 0 0 5px'}}>
                                                                <Typography variant="subtitle2" component='div' color="text.secondary" >{ entry.caption }</Typography>
                                                                <Box sx={{ flex: '1 1 auto' }} />
                                                                { entry.link ? (
                                                                    <Link variant="body2" target='blank' href={ entry.link }>{ entry.content }</Link>
                                                                ) : (
                                                                    <Typography variant="body2">{ entry.content }</Typography>
                                                                )}
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </div>                                            
                                            ))}                                        
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>

                        

                        </>
                        )}

                        <GreyBox className="box-grey" pt={3} pb={ onMobile ? drawerBleeding + 15 + 'px' : 3}>
                            { (dossier.hideadvisor === 0 || dossier.hidecompany === 0) && 
                                <Container sx={{display: 'flex', flexDirection: onMobile ? 'column' : 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
                                    { dossier.hideadvisor === 0 &&
                                        <Box display='flex' flexDirection='row' mt={1} mb={ onMobile ? 3 : 0}>
                                            { (colorScheme === 'dark' && dossier.advisor.imagedark_path) ? (
                                                <img src={ dossier.advisor.imagedark_path} alt={dossier.advisor.image_alt} style={{maxHeight: '80px', borderRadius: '5px'}}/>
                                            ) : (
                                                <img src={ dossier.advisor.image_path} alt={dossier.advisor.image_alt} style={{maxHeight: '80px', borderRadius: '5px'}}/>
                                            )}
                                            <Box pl={1}>
                                                <Typography variant='body1' fontWeight='bolder'>{dossier.advisor.name}</Typography>
                                                <div><Link variant="body2" href={'mailto:' + dossier.advisor.email}>{dossier.advisor.email}</Link></div>
                                                <div><Link variant="body2" href={'tel:' + dossier.advisor.phone}>{dossier.advisor.phone}</Link></div>
                                            </Box>
                                        </Box>
                                    }
                                    { (dossier.hideadvisor === 0 && dossier.hidecompany === 0 ) &&
                                        <Divider orientation={onMobile ? 'horizontal' : 'vertical'} variant="middle" flexItem sx={{ml: 3, mr: 3}}/>
                                    }
                                    { dossier.hidecompany === 0 &&
                                        <Box display='flex' flexDirection='row' mt={ onMobile ? 3 : 1}>
                                            { (colorScheme === 'dark' && dossier.company.imagedark_path) ? (
                                                <img src={ dossier.company.imagedark_path} alt={dossier.company.image_alt} style={{maxHeight: '80px', borderRadius: '5px'}}/>
                                            ) : (
                                                <img src={ dossier.company.image_path} alt={dossier.company.image_alt} style={{maxHeight: '80px', borderRadius: '5px'}}/>
                                            )}
                                            <Box pl={1}>
                                                <Typography variant='body1' fontWeight='bolder'>{dossier.company.name}</Typography>
                                                <Typography variant='body2'>{dossier.company.address}</Typography>
                                                <Typography variant='body2' component='span' mr={1}>{dossier.company.zip}</Typography>
                                                <Typography variant='body2' component='span'>{dossier.company.city}</Typography>
                                                <Typography variant='body2'>{dossier.company.country}</Typography>
                                                <div><Link variant="body2" href={'tel:' + dossier.company.phone}>{dossier.company.phone}</Link></div>
                                                <div><Link variant="body2" href={'mailto:' + dossier.company.email}>{dossier.company.email}</Link></div>
                                                <div><Link variant="body2" target='blank' href={dossier.company.web}>{dossier.company.web}</Link></div>
                                            </Box>
                                        </Box>
                                    }
                                </Container>
                            }
                            <Box sx={{margin: 'auto 0', marginTop: '30px'}} display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                                <img width='32px' height='32px' src="/favicon-32x32.png" alt="my-drive.id Logo" style={{opacity: '0.5'}}/>
                                <Typography sx={{textAlign: 'center', ml: 1, fontSize: '0.7rem', color: '#d3d3d3'}}>powered by my-drive.id</Typography>
                            </Box>
                        </GreyBox>
                    </div>
                }






                <OrderDrawer
                    className='order-drawer'
                    sx={{maxHeight: '80%'}}
                    open={ openActionDrawer }
                    onClose={() => setOpenActionDrawer(false)}
                    mode={ actionMode }
                    orderEmail={ dossier ? dossier.emailorder : '' }
                    quoteEmail={ dossier ? dossier.emailquote : ''}
                    myDriveId={ dossier ? dossier.mydriveid : ''}
                />
            </ThemeProvider>            
        </div>
     );
}
 
export default Mydriveid;