import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Entries abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.
//return: ein Object, mit status, message, und den Entries als results
export const fetchEntries = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'entries/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Entry abfragen mit einer id
//id: id des Entry die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Entry als results
export const fetchEntry = async ( id ) => {
    var uri = await getApiPath() + 'entries/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Alle Entries eines Blocks abfragen
//block_id: id des Blocks, von dem die Entries zurückgegeben werden
//return: ein Object, mit status, message, und den Entries als results
export const fetchEntriesFromBlock = async ( block_id ) => {
    var uri = await getApiPath() + 'entries/?block=' + block_id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuen Entry erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben. Ausserdem die id des neuen Datensatzes
export async function createEntry(block_id, group_id, caption_id, content_id, link_id, apikey){
    const payload = {
        block_id: block_id,
        group_id: group_id,
        caption_id: caption_id,
        content_id: content_id,
        link_id: link_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'entries/', 'POST', headers, JSON.stringify(payload))
}


//Ein Entry ändern.
//id:   id des Entry das geändert werden soll
//new{x}: die neuen Werte des Entry
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editEntry(id, block_id, group_id, caption_id, content_id, link_id, apikey){
    const payload = {
        block_id: block_id,
        group_id: group_id,
        caption_id: caption_id,
        content_id: content_id,
        link_id: link_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'entries/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Entry löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteEntry(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'entries/' + id, 'DELETE', headers)
}
