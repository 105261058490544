import { parseBoolean } from "../includes/coreFunc"
import { createContext, useEffect, useState } from 'react'
import { login } from '../includes/dbUsersFunc'
import { checkApi, getVersion } from "../includes/dbUtilities";
import { compareVersions } from "compare-versions";
import { getApiPath } from "../includes/coreFunc"


export const AuthContext = createContext(null);

const AuthProvider = ( { children }) => {
    const [user, setUser] = useState(null)
    const [phpVersion, setPhpVersion] = useState("0")
    const [validPhp, setValidPhp] = useState(false)
    const [apiPath, setApiPath] = useState(null);
    const [validApi, setValidApi] = useState(true);
    const [validApipath, setValidApipath] = useState(true);
   

    useEffect(() =>{

        (async () => {
            //Wenn die Seite neu geladen wird, werden alle useState-Variablen zurückgesetzt. Wir können aber schauen, ob diese in der Session gespeichert sind.
            if (sessionStorage.getItem("user") !== null){
                //Der User ist in der Session schon gesetzt. Das Session-Item ist aber JSON, also in ein Object umwandeln.
                const user = JSON.parse(sessionStorage.getItem("user"))
                setUser(user)
            }
            
            const php = await getVersion()
            setPhpVersion(php)
            setValidPhp( compareVersions(php, '8.1.22') >= 0 )

            const path = await getApiPath()
            setApiPath(path)
            setValidApi(await checkApi(path))
            setValidApipath(path.slice(-1) === '/')
        })();
    },[])

    //Gibt true wenn es ein Login gibt. Ist besser als user abzufragen, insbesondere beim Reload der Seite dauert es einen Moment mit der user aus dem sessionStorage wieder gesetzt ist.
    const isLoggedin = () => {
        if(user || sessionStorage.getItem("user") !== null){
            return true
        }else{
            return false
        }        
    }

    //Gibt true wenn de eingeloggte user admin-Rechte hat. Ist besser als user abzufragen, insbesondere beim Reload der Seite dauert es einen Moment mit der user aus dem sessionStorage wieder gesetzt ist.
    const isAdmin = () => {
        if(!isLoggedin()){
            return false;
        }else if( user ){
            return parseBoolean(user.administrator)
        }else{
            const user = JSON.parse(sessionStorage.getItem("user"))
            return parseBoolean(user.administrator)
        }        
    }

    const handleLogin = async (username, password) => {
        const answer = await login(username, password)

        if(answer.status*1 === 0){
            //Error beim Login.
            return {status: 0, message: answer.message}
        }else{
            //Erfolgreicher login
            sessionStorage.setItem('user', JSON.stringify(answer.results));
            setUser(answer.results)
            return {status: 1, message: "Erfolgreicher Login"}
        }
    }

    const handleLogout = async () => {
        new Promise((resolve) => {
            sessionStorage.removeItem('user');
            setUser(null);
            resolve(true)
        })
    }

    const value = {
        user,
        phpVersion,
        validPhp,
        apiPath,
        validApi,
        validApipath,
        apikey: user ? user.apikey : null,
        onLogin: handleLogin,
        onLogout: handleLogout,
        isLoggedin,
        isAdmin
    }

    return ( 
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
     );
}
 
export default AuthProvider;