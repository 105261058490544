import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


export const getVersion = async () => {
    const headers = {
            "Content-Type": "application/json",
    }

    const info = "version"
    const answer = await coreFetch(await getApiPath() + 'utilities/' + info, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results
    }else{
        return "0"
    }

}

export const checkApi = async ( path ) => {
    const response = await coreFetch(await path + 'languages/?system', 'GET', {"Content-Type": "application/json"})
    if (!response || response.status === 0 ){
        return false
    }else{
        return true
    }
}