import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


export const sendEmail = async (target, from, subject, message) => {
    const payload = {
        target: target,
        from: from,
        subject: subject,
        message: message
    }

    const headers = {
            "Content-Type": "application/json",
    }

    return await coreFetch(await getApiPath() + 'email/', 'POST', headers, JSON.stringify(payload))
}