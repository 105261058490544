import { Button, IconButton, Box, Container, Paper, TextField, Typography, Stack, FormGroup, FormControlLabel, Checkbox, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useContext, useState } from "react"
import { useQuery } from "react-query"
import { fetchNeedTranslation, fetchSource, editSource } from "../includes/dbSourcesFunc"
import { fetchTranslations, createTranslation, deleteTranslation, editTranslation } from '../includes/dbTranslationsFunc'
import { AuthContext } from '../components/AuthProvider'
import { LanguageContext } from '../components/LanguageProvider'
import { DataGrid } from "@mui/x-data-grid"
import { useSnackbar } from 'notistack'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import TranslateIcon from '@mui/icons-material/Translate'
import CloseIcon from '@mui/icons-material/Close'


const columns = [
    { field: 'source', headerName: 'Source', width: 200 },
    { field: 'languages', headerName: 'Sprachen', width: 100 }
];



const Translationspage = () => {
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(50)
    const [selectedId, setSelectedId] = useState([])
    const [translations, setTranslations] = useState({})
    const [values, setValues] = useState({})
    const { languages } = useContext(LanguageContext)
    const { apikey } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['sources', apikey, rowsCount, search], () => fetchNeedTranslation(rowsCount, 0, search))
    const { enqueueSnackbar } = useSnackbar()


    //Eine Source bearbeiten
    const handleEdit = async (id) => {
        const sourceFetch = await fetchSource(id)
        if(sourceFetch && sourceFetch.status === 1){
            if(sourceFetch.count === 1){
                const source = sourceFetch.results
                const translationFetch = await fetchTranslations(0, source.id)
                const translations = Object.fromEntries(translationFetch.results.map((translation) => [translation.language_id, [translation.translation, translation.id] ]));
                //Also: Translations ist ein Obj. Jede Language hat ein Property. Das Property hat die id der language. Der Wert ist ein Array mit zwei Werten.
                //Erster Wert ist die translation. Zweiter Wert ist die id der translation selber. Diese id gibt es aber nur, wenn eine source bearbeitet wird. Bei einer neuen Source, ist die translation noch nicht gespeichert und kann auch keine id haben.
                //Wenn aber im edit-mode kann es auch sein, dass eine source noch nicht für alle Werte eine translation hat.
                setTranslations(translations)
                setValues(source)
            }else{
                enqueueSnackbar('Es existiert keine Source mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Eine Source erstellen, oder eine bestehende Source bearbeiten.
    const handleSave = async () => {

        //Source ändern.
        const res = await editSource(values.id, values.source, values.key === '' ? null : values.key, values.notranslations, apikey)
        if(res && res.status === 1){
            enqueueSnackbar("Source erfolgreich geändert.", {variant: 'success'})
            //translation erstellen/ändern/löschen.
            //erstellen: im Array ist eine translation als erster Wert, aber keine id als zweiter Wert
            //ändern: im Array ist eine translation als erster Wert, und eine id als zweiter Wert
            //löschen: im Array ist eine translation als erster Wert die ein leerer String ist, und eine id als zweiter Wert
            
            for (const language_id in translations) {
                const arr = translations[language_id]
                var answer
                var operation
                if(arr.length === 1){
                    //erstellen
                    answer = await createTranslation(values.id, language_id, arr[0], apikey)
                    operation = "erstellen"
                }else if(arr[0] === ""){
                    //löschen  Der zweite Wert im Array ist die id der translation
                    answer = await deleteTranslation(arr[1], apikey)
                    operation = "löschen"
                }else{
                    //ändern
                    answer = await editTranslation(arr[1], values.id, language_id, arr[0], apikey)
                    operation = "ändern"
                }
                if( answer.status === 0){
                    enqueueSnackbar(`Fehler beim ${operation} der Übersetzung ` + translations[language_id][0] + ". " + answer.message, {variant: 'error'})
                }
            }
            refetch()
        }else{
            enqueueSnackbar(res.message, {variant: 'error'})
        }
    }

    const handleNext = () => {
        if(data && data.status === 1 && data.count > 0){
            let id = data.results[0].id
            if(selectedId === id && data.count > 1 ){
                id = data.results[1].id                                                
            }
            handleEdit(id)
            setSelectedId(id)
        }else{
            setValues({})
            setSelectedId([])
        }
    }


    return (
        <Container style={{display: 'flex', flexDirection: 'column'}}>
            <Box style={{ height: '60vh'}}>
                <Typography variant="h1">Übersetzungen</Typography>
                <Box display='flex' flexDirection='row' alignItems='flex-start' sx={{height: '100%'}}>
                    <div style={{height: '100%', minWidth: '350px'}}>


                        <TextField
                            autoComplete="off"
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            value={search ? search : ''}
                            onChange={(e) => {
                                setValues({})
                                setSearch(e.target.value)
                            }}
                            sx={{mb: 1}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => {
                                            setValues({})
                                            setSearch('')
                                        }}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200, mb: 2 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton color="primary" onClick={refetch} sx={{ml: 2, mt: 1}}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}                        
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            pageSize={50}
                            rowsPerPageOptions={[50]}                    
                            disableColumnFilter
                            onRowClick={async (e) => await handleEdit(e.id)}
                            onSelectionModelChange={(id) => setSelectedId(id[0])}
                            selectionModel={selectedId}
                        />
                    </div>
                    <Paper sx={{ml: 3, width: '100%', p: 2}}>
                        <Typography variant='h5' mb={5}>Edit</Typography>
                        <Stack spacing={3}>
                            <TextField
                                variant='standard'
                                label='Source'
                                disabled
                                fullWidth
                                value={ values.source ? values.source : ''}
                            />
                            <TextField
                                variant='standard'
                                label='Key'
                                disabled
                                fullWidth
                                value={ values.key ? values.key : '' }
                            />
                            <FormGroup>
                                <FormControlLabel label="Source benötigt keine Übersetzung" control={
                                    <Checkbox
                                        id="notranslations"
                                        checked={ values.notranslations === 1 ? true : false }
                                        onChange={(e) => {
                                            const newValue = e.target.checked === true ? 1 : 0
                                            setValues(prev =>({
                                                ...prev,
                                                notranslations: newValue
                                            }))
                                        }}
                                    />
                                } />
                            </FormGroup>
                            { (values.notranslations === 0 && languages && languages.length > 1) &&
                                <Stack spacing={2} className="translations">
                                    <Typography variant='h6' sx={{ marginTop: 2 }}>Übersetzungen</Typography>
                                    {languages.map(language => {
                                        if(language.system*1===0){
                                            return (
                                                <Stack key={language.id} direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                                                    {language.leo && 
                                                        <a href={`https://dict.leo.org/${language.leo}/${values.source}`} target="_blank" rel="noopener noreferrer">
                                                            <TranslateIcon color='info'/>
                                                        </a>
                                                    }
                                                    <TextField                                                            
                                                        variant='standard'
                                                        label={language.name}
                                                        fullWidth
                                                        value={ translations[language.id] ? translations[language.id][0] : ""}
                                                        onChange={(e) => {
                                                            //Also: Translations ist ein Obj. Jede Language hat ein Property. Das Property hat die id der language. Der Wert  ist ein Array mit zwei Werten.
                                                            //Erster Wert ist die translation. Zweiter Wert ist die id der tranlation selber. Diese id gibt es aber nur, wenn eine source bearbeitet wird. Bei einer neuen Source, ist die translation noch nicht gespeichert und kann auch keine id haben.
                                                            //Wenn aber im edit-mode kann es auch sein, dass eine source noch nicht für alle Werte eine translation hat.
                                                            const arr = translations[language.id] && translations[language.id].length > 1 ? [e.target.value, translations[language.id][1]] : [e.target.value]
                                                            setTranslations(prev =>({
                                                                ...prev,
                                                                [language.id]: arr
                                                            }))
                                                        }}
                                                    />
                                                </Stack>
                                            )
                                        }else{
                                            return null
                                        }
                                    })}
                                </Stack>
                            }
                            <Stack direction='row' spacing={1}>
                                { values.id && 
                                <>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            handleSave()
                                            handleNext()
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant='contained'
                                        type='reset'
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </>
                                }
                            </Stack>
                        </Stack>
                    </Paper>
                </Box>
            </Box>
        </Container>        
    );
}
 
export default Translationspage;