import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import debounce from "lodash.debounce"
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { InputAdornment, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchFiles, fetchFile, createFile, editFile, deleteFile, fileNameExitst, uploadFile} from '../includes/dbFilesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'
import { getRootPath } from '../includes/coreFunc'



const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'path', headerName: 'Pfad', width: 200 },
    { field: 'extension', headerName: 'Ext', width: 60 },
    { field: 'description', headerName: 'Beschreibung', width: 200 },
    { field: 'alt', headerName: 'Alt', width: 150 },
    { field: 'filetype', headerName: 'Typ', width: 80 },
];

const Filespage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(15)
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [file, setFile] = useState(null)
    const [fileObjectUrl, setFileObjectUrl] = useState('')
    const [filedark, setFiledark] = useState(null)
    const [filedarkObjectUrl, setFiledarkObjectUrl] = useState('')
    const [errors, setErrors] = useState({})
    const [ rootPath, setRootPath ] = useState('')
    const { data, isLoading, refetch} = useQuery(['files', search, rowsCount], () => fetchFiles(search, rowsCount))
    const searchInput = useRef()
    const imgRefCreate = useRef(null)
    const imgRefCreateDark = useRef(null)
    const imgRefEdit = useRef(null)
    const imgRefEditDark = useRef(null)

    useEffect(() =>{
        (async () => {
            if(values.name){
                const answer = await fileNameExitst(values.name)
                if(answer){
                    setErrors(prev => ({
                        ...prev,
                        name: 'Datei existiert bereits und wird überschrieben'
                    }))
                }else{
                    setErrors(prev => ({
                        ...prev,
                        name: null
                    }))                    
                }
            }else{
                setErrors(prev => ({
                    ...prev,
                    name: null
                })) 
            }
            setRootPath(await getRootPath())
        })();
    },[ values.name ])


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchFile(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein File mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({name: '', namedark: '', extension: '', description: '', alt: '', filetype: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setFile(null)
            setFileObjectUrl('')
            setFiledark(null)
            setFiledarkObjectUrl('')
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setFiledark(null)
            setFiledarkObjectUrl('')
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }


    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }


    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = () => {
        let collect = {}
        let namecheck = /[^a-zA-Z0-9_.\-']/
        
        if(!values.name){
            collect.name = 'Name eingeben.'
        }else if(values.name.includes('_dark.')){
            collect.name = "Name darf nicht _dark enthalten!"
        }else if(namecheck.test(values.name)){
            collect.name = "Es sind nur Buchstaben, Zahlen und '_' zulässig!"
        }
        
        if(mode === 'create' && !file){
            collect.file = 'Bitte eine Datei auswählen!'
        }

        setErrors({
            ...collect
        })

        return Object.keys(collect).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Dark-File upload
            const darkname = filedark ? appendToFilename(values.name, '_dark') : null
            if (filedark && await !uploadFile(darkname, filedark, apikey)){
                enqueueSnackbar('File für Dark-Theme konnte nicht auf den Server kopiert werden.', {variant: 'error'})
                return false
            }

            //Neues File erstellen.
            const res = await uploadFile(values.name, file, apikey) //upload auf den Server
            if(res.status === 0){
                enqueueSnackbar('File konnte nicht auf den Server kopiert werden.', {variant: 'error'})
            }else{
                const res = await createFile(values.name, darkname, values.extension, values.description, values.alt, values.filetype, apikey)
                if(res && res.status === 1){
                    enqueueSnackbar("File erfolgreich angelegt.", {variant: 'success'})
                    handleCloseDrawer()
                    refetch()
                }else{
                    enqueueSnackbar(res.message, {variant: 'error'})
                }
            }
        }else if(mode === 'edit'){
            //Dark-File upload
            var pathdark = values.pathdark
            if (filedark){
                const darkname = filedark ? appendToFilename(values.name, '_dark') : null
                if(await uploadFile(darkname, filedark, apikey)){
                    pathdark = appendToFilename(values.path, '_dark')
                }else{
                    enqueueSnackbar('File für Dark-Theme konnte nicht auf den Server kopiert werden.', {variant: 'error'})
                    return false
                }
            }

            //File ändern.
            const res = await editFile(values.id, values.description, values.alt, pathdark, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("File erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Ein File löschen
    const handleDelete = async (id) => {
        const result = await deleteFile(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("File erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteFile(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    //Beim Laden eines Bilds! Kontrolle der Dimensionen
    const handleImageLoad = ( { target:img }) => {
        let width = 200
        let height = 200

        
        if(imgRefCreate.current){
            if(imgRefCreate.current.naturalHeight > height){height = imgRefCreate.current.naturalHeight}
            if(imgRefCreate.current.naturalWidth > width){width = imgRefCreate.current.naturalWidth}
        }

        if(imgRefCreateDark.current){
            if(imgRefCreateDark.current.naturalHeight > height){height = imgRefCreateDark.current.naturalHeight}
            if(imgRefCreateDark.current.naturalWidth > width){width = imgRefCreateDark.current.naturalWidth}
        }

        if(imgRefEdit.current){
            if(imgRefEdit.current.naturalHeight > height){height = imgRefEdit.current.naturalHeight}
            if(imgRefEdit.current.naturalWidth > width){width = imgRefEdit.current.naturalWidth}
        }

        if(imgRefEditDark.current){
            if(imgRefEditDark.current.naturalHeight > height){height = imgRefEditDark.current.naturalHeight}
            if(imgRefEditDark.current.naturalWidth > width){width = imgRefEditDark.current.naturalWidth}
        }



        if (width + height > 400){
            setErrors(prev => ({
                ...prev,
                image: `Dimensionen sollten nicht grösser als 200x200px sein! Aktuell: ${width}x${height}`
            }))
        }else{
            setErrors(prev => ({
                ...prev,
                image: null
            }))
        }
    }


    //Einem Dateiname ein Suffix hinzufügen. Bsp: uploads/image.png (string: .tmp)-> uploads/image.tmp.png
    function appendToFilename(filename, string){
        var dotIndex = filename.lastIndexOf(".");
        if (dotIndex === -1) return filename + string;
        else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
    }


    
    return (
        <Container>
            <Typography variant="h1">
                Files
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                            <AddIcon />
                        </IconButton>
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}
                            disableColumnFilter
                            onRowClick={(e) => handleEdit(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'File ändern...'}
                        { mode === "create" && 'File upload...'}
                    </Typography>
                    {values.filetype === 'image' && mode === 'edit' &&
                    <Stack direction='row' mb={2}>
                        <Box sx={{border: '1px solid silver', borderRadius: '5px', p: 1, mr: 2}}>
                            <Typography mb={1} variant='body2' color={grey[500]}>Light Theme</Typography>
                            <img src={rootPath + values.path} height={"50px"} alt={values.alt}/>
                        </Box>
                            <Box sx={{border: '1px solid silver', borderRadius: '5px', backgroundColor: grey[800], p: 1}}>
                                <Typography mb={1} variant='body2' color={grey[500]}>Dark Theme</Typography>
                                {values.pathdark ? (
                                <>
                                    <img src={rootPath + values.pathdark} height={"50px"} alt={values.alt}/>
                                    <IconButton color='primary'
                                        onClick={() => {
                                            setValues(prev =>({
                                                ...prev,
                                                pathdark: null
                                            }))
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </> ) : (
                                <>
                                    <input
                                        color="primary"
                                        accept="image/*" 
                                        type="file"
                                        onChange={(e) => {
                                            const type = e.target.files[0].type.split('/')
                                            if (type[0] === values.filetype) {
                                                setFiledark(e.target.files[0])
                                                setFiledarkObjectUrl(URL.createObjectURL(e.target.files[0]))
                                            }else{
                                                enqueueSnackbar('Muss ein Bild sein.', {variant: 'error'})
                                            }
                                        }}
                                        id="icon-button-filedark"
                                        style={{ display: 'none', }}
                                    />
                                    <Box display='flex' flexDirection='row' alignItems='center'>
                                        <label htmlFor="icon-button-filedark">
                                            <Button sx={{mt: 1}} variant="contained" component="span" color="primary">Suchen</Button>
                                        </label>
                                        <IconButton color='primary'
                                            onClick={() => {
                                                setFiledark(null)
                                                setFiledarkObjectUrl(null)
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Box>
                                    {filedarkObjectUrl &&
                                        <>
                                        <img ref={imgRefCreateDark} onLoad={handleImageLoad} alt="not fount" style={{marginTop: '10px', maxHeight: '50px', maxWidth: '200px'}} src={filedarkObjectUrl} />
                                        <Typography variant='body2' color={grey[500]} sx={{mt: 1}}>{ filedark && filedark.name }</Typography>
                                        <Typography variant='body2' color={grey[500]}>{ imgRefCreateDark.current && (imgRefCreateDark.current.naturalWidth + 'x' + imgRefCreateDark.current.naturalHeight)}</Typography>
                                        </>
                                    }
                                </>
                                )}
                            </Box>                        
                    </Stack>
                    }
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                { mode === 'create' &&
                                    <Stack direction='row' alignItems="self-start">
                                        <Stack direction='column' alignItems='self-start' sx={{border: '1px solid silver', borderRadius: '5px', p: 1}}>
                                            <Typography variant='body1'>File</Typography>
                                            <input
                                                color="primary"
                                                accept="*"
                                                type="file"
                                                onChange={(e) => {
                                                    if(e.target.files[0]){
                                                        const type = e.target.files[0].type.split('/')
                                                        setValues(prev =>({
                                                            ...prev,
                                                            extension: type[1],
                                                            filetype: type[0],
                                                            name: e.target.files[0].name,
                                                        }))
                                                        setFile(e.target.files[0])
                                                        setFileObjectUrl(URL.createObjectURL(e.target.files[0]))
                                                        setFiledark(null)
                                                        setFiledarkObjectUrl('')
                                                    }
                                                }}
                                                id="icon-button-file"
                                                style={{ display: 'none', }}
                                            />
                                            <label htmlFor="icon-button-file">
                                                <Button sx={{mt: 1}} variant="contained" component="span" color="primary">Suchen</Button>
                                            </label>
                                            {values.filetype === 'image' && mode === 'create' &&
                                                <img ref={imgRefCreate} onLoad={handleImageLoad} alt="not fount" style={{marginTop: '10px', maxHeight: '50px', maxWidth: '200px'}} src={fileObjectUrl} />
                                            }
                                            <Typography variant='body2' color={grey[500]} sx={{mt: 1}}>{ file && file.name }</Typography>
                                            <Typography variant='body2' color={grey[500]}>{ imgRefCreate.current && (imgRefCreate.current.naturalWidth + 'x' + imgRefCreate.current.naturalHeight)}</Typography>
                                        </Stack>
                                        {values.filetype === 'image' && mode === 'create' &&
                                            <Stack direction='column' alignItems='self-start' ml={2} sx={{border: '1px solid silver', backgroundColor: grey[800], borderRadius: '5px', p: 1}}>
                                                <Typography variant='body1' color={grey[100]}>File für Dark-Theme</Typography>
                                                <input
                                                    color="primary"
                                                    accept="image/*" 
                                                    type="file"
                                                    onChange={(e) => {
                                                        const type = e.target.files[0].type.split('/')
                                                        if (type[0] === values.filetype) {
                                                            setFiledark(e.target.files[0])
                                                            setFiledarkObjectUrl(URL.createObjectURL(e.target.files[0]))
                                                        }else{
                                                            enqueueSnackbar('Muss ein Bild sein.', {variant: 'error'})
                                                        }
                                                    }}
                                                    id="icon-button-filedark"
                                                    style={{ display: 'none', }}
                                                />
                                                <Box display='flex' flexDirection='row' alignItems='center'>
                                                    <label htmlFor="icon-button-filedark">
                                                        <Button sx={{mt: 1}} variant="contained" component="span" color="primary">Suchen</Button>
                                                    </label>
                                                    <IconButton color='primary'
                                                        onClick={() => {
                                                            setFiledark(null)
                                                            setFiledarkObjectUrl(null)
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Box>
                                                {filedarkObjectUrl &&
                                                    <>
                                                    <img ref={imgRefCreateDark} onLoad={handleImageLoad} alt="not fount" style={{marginTop: '10px', maxHeight: '50px', maxWidth: '200px'}} src={filedarkObjectUrl} />
                                                    <Typography variant='body2' color={grey[500]} sx={{mt: 1}}>{ filedark && filedark.name }</Typography>
                                                    <Typography variant='body2' color={grey[500]}>{ imgRefCreateDark.current && (imgRefCreateDark.current.naturalWidth + 'x' + imgRefCreateDark.current.naturalHeight)}</Typography>
                                                    </>
                                                }
                                            </Stack>
                                        }
                                    </Stack>
                                }
                                {(file && file.size > 1000000) && 
                                    <Alert severity='warning'>{ 'Die Dateigrösse ist grösser als 1MB. Vorsicht bei sehr grossen Dateien. Filesize: ' + file.size / 1000000 + 'MB' }</Alert>
                                }
                                {errors.image && 
                                    <Alert severity='warning'>{ errors.image }</Alert>
                                }
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }                                
                                <TextField
                                    variant='standard'
                                    label='Name'
                                    id='name'
                                    disabled={ mode === 'edit' }
                                    required
                                    fullWidth
                                    helperText={ errors.name && mode === 'create' ? errors.name : '' }
                                    error={ errors.name && mode === 'create' ? true : false }
                                    value={ values.name }
                                    onChange={(e) => {
                                        if(filedark){
                                            setValues(prev =>({
                                                ...prev,
                                                name: e.target.value,
                                                namedark: appendToFilename(e.target.value, '_dark')
                                            }))
                                        }else{
                                            setValues(prev =>({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='Extension'
                                    required
                                    disabled
                                    id='extension'
                                    fullWidth
                                    value={ values.extension ? values.extension : '' }
                                />
                                <TextField
                                    variant='standard'
                                    label='Typ'
                                    required
                                    disabled
                                    id='filetype'
                                    fullWidth
                                    value={ values.filetype ? values.filetype : '' }
                                />                                
                                <TextField
                                    variant='standard'
                                    label='Beschreibung'
                                    id='description'
                                    fullWidth
                                    value={ values.description }
                                    inputProps={ {maxLength: 128}}
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            description: e.target.value
                                        }))
                                    }}
                                />
                               <TextField
                                    variant='standard'
                                    label='Alternativer Text'
                                    id='alt'
                                    fullWidth
                                    value={ values.alt }
                                    inputProps={ {maxLength: 128}}
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            alt: e.target.value
                                        }))
                                    }}
                                />
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const res = handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    Das File kann nur gelöscht werden, wenn es in keinem Block verwendet wird!<br/>
                                    Ausserdem sollte der Pfad nicht in einem Entry als Link verwendet werden.
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Files wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Das File kann nur gelöscht werden, wenn es in keinem Block verwendet wird!<br/>
                        Ausserdem sollte der Pfad nicht in einem Entry als Link verwendet werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Filespage;