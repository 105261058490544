import { useContext } from "react";
import { AuthContext } from "../components/AuthProvider";
import { Alert, Typography } from "@mui/material";


const Errorbar = () => {
    const { phpVersion, validPhp, apiPath, validApi, validApipath } = useContext(AuthContext)

    return (
        <div>
            {(!validPhp && validApi )&&
                <Alert severity="error">Die PHP Version der API ist veraltet. Es wird {phpVersion && phpVersion} verwendet. Minimal 8.1.22 ist notwendig. Es werden Fehler auftretten.</Alert>
            }
            {!validApi &&
                <Alert severity="error">Die API ist nicht erreichbar. {apiPath}</Alert>
            }
            {!validApipath &&
                <Alert severity="error">Die Api-Adresse muss mit einem "/" enden. {apiPath}</Alert>
            }
            <div style={{marginLeft: '20px'}}>
                <Typography variant="caption" sx={{ color: 'silver' }} component='span'>
                    PHP: {phpVersion && phpVersion}
                </Typography>
                <Typography variant="caption" sx={{ color: 'silver', ml: 2}} component='span'>
                    API: {apiPath && apiPath}
                </Typography>
            </div>
        </div>
    );
}

export default Errorbar;