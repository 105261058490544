import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Tooltip, Typography } from '@mui/material'
import { fetchImages } from '../includes/dbFilesFunc'
import debounce from "lodash.debounce"
import { getRootPath } from '../includes/coreFunc'


function ImageDialog(props) {
    const { onClose, open, ...other } = props;
    const [options, setOptions] = useState([])
    const [rootPath, setRootPath] = useState('')

    useEffect(() => {
        (async () => {
            setRootPath(await getRootPath())
        })();
    }, [open]);

    const handleEntering = () => {

    };

    const handleCancel = () => {
        onClose();
    };

    const handleSelect = (option) => {
        onClose(option);
    };

    const handleSearch = async (search) => {
        if (search === '') {
            setOptions([])
        } else {
            debounceSearch(search)
        }
    }

    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce(async (value) => {
            const answer = await fetchImages(value, 50)
            if (answer && answer.status === 1) {
                setOptions(answer.results)
            } else {
                setOptions([])
            }
        }, 200)
        , [])

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '70%', height: '80%' } }}
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Images</DialogTitle>
            <DialogContent dividers>
                <TextField fullWidth autoComplete='off' label='Suche...' onChange={(e) => handleSearch(e.target.value)} />
                <Typography variant="caption" sx={{color:'silver'}} >% für alle</Typography>
                {options &&
                    options.map((option, index) => (
                        <Tooltip key={index}
                            title={
                                <>
                                    <p>{option.name}</p>
                                    <p>{option.description}</p>
                                </>
                            }
                        >
                            <Button
                                sx={{ width: '100px', height: '100px', m: 1 }}
                                variant='outlined'
                                key={option.id}
                                onClick={() => handleSelect(option)}
                            >
                                <img src={rootPath + option.path} alt={option.alt} style={{ maxHeight: 100, maxWidth: 100, margin: 5 }} />
                            </Button>
                        </Tooltip>
                    ))
                }

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ImageDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ImageDialog;