import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


export const fetchQueries = async ( limit, search, group ) => {
    const params = new URLSearchParams({})
    if(group && group === true){ params.append('group', true) }
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'queries/' + (paramsStr !== '?' ? paramsStr : '')
    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}



export const fetchQuery = async ( id ) => {
    var uri = await getApiPath() + 'queries/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


/**
 * Eine Query löschen.
 * @param {integer} id Id der Query die gelöscht wird
 * @param {string} apikey Der API-Key für den Zugriff auf die <API></API>
 * @returns {object} Status (1: Erfolg, 0: Fehler) und eine Message falls der Status nicht 1 ist
 */
export async function deleteQuery(id, apikey){
    const payload = {}

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'queries/' + id, 'DELETE', headers, JSON.stringify(payload))
}