import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import debounce from "lodash.debounce"
import { useContext, useState, useCallback, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Menu, MenuList, ListItemIcon, ListItemText, Tab, Skeleton, Tooltip, Paper, Drawer, Alert, InputAdornment, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DataGrid } from "@mui/x-data-grid"
import { fetchTemplates, fetchTemplate, deleteTemplate, createTemplate, editTemplate, duplicteTemplate } from '../includes/dbTemplatesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { checkSource } from '../includes/dbSourcesFunc'
import ImageDialog from '../components/ImageDialog'
import SourceTextfield from '../components/SourceTextfield'
import TemplateEntries from '../components/TemplateEntries'
import { getRootPath } from '../includes/coreFunc'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'info', headerName: 'Info', width: 500 }
];

const Templatespage = () => {
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState('form')
    const [search, setSearch] = useState('')
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [imageDialogOpen, setimageDialogOpen] = useState(false)
    const [rowsCount, setRowsCount] = useState(15)
    const [editMenuAnchor, setEditMenuAnchor] = useState(null)
    const [rootPath, setRootPath] = useState('')
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['templates', rowsCount, search], () => fetchTemplates(rowsCount, search))
    const searchInput = useRef()
    const { enqueueSnackbar } = useSnackbar()
    const { id: templateId } = useParams()   //ID aus der URL, wenn also der Template 84 aufgerufen werden soll.


    useEffect(() => {
        (async () => {
            if(templateId){
                //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
                handleEdit( templateId )            
            }
            setRootPath(await getRootPath())
        })();
    // eslint-disable-next-line
    },[])


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setSelectedTab('form')
            setErrors({})
            setValues({name: '', info: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleEdit gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }
    

    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchTemplate(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Template mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }
    

    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = () => {
        let collect = {}

        if(!values.name){
            collect.name = 'Bitte ein Name eingeben.'
        }

        if(!values.info){
            collect.info = 'Bitte ein Info eingeben.'
        }

        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }

    //Ein Template erstellen, oder eine bestehendes Template bearbeiten.
    const handleSave = async () => {
        if(mode === 'create'){
            //Kontrollieren, ob Source für Label schon existiert, ansonsten anlegen. Es wird entweder die id der Source oder False zurückgegeben.
            let title_id = null
            if(values.title_source !== '' && values.title_source !== null){
                title_id = await checkSource(values.title_source ? values.title_source : null, apikey)
            }
            let description_id = null
            if(values.description_source !== '' && values.description_source !== null){
                description_id = await checkSource(values.description_source ? values.description_source : null, apikey)
            }
            
            //Neues Template erstellen.
            const res = await createTemplate(values.name, values.info, values.image_id, title_id, description_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Template erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }

        }else if(mode === 'edit'){
            //Kontrollieren, ob Source für Label schon existiert, ansonsten anlegen. Es wird entweder die id der Source oder False zurückgegeben.
            let title_id = null
            if(values.title_source !== '' && values.title_source !== null){
                title_id = await checkSource(values.title_source ? values.title_source : null, apikey)
            }
            let description_id = null
            if(values.description_source !== '' && values.description_source !== null){
                description_id = await checkSource(values.description_source ? values.description_source : null, apikey)
            }

            //Template ändern.
            const res = await editTemplate(values.id, values.name, values.info, values.image_id, title_id, description_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Template erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }

        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }

    //Ein Template löschen
    const handleDelete = async (id) => {
        const result = await deleteTemplate(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
        }else{
            enqueueSnackbar("Template erfolgreich gelöscht.", {variant: 'success'})
            setIsDrawerOpen(false)
        }
        refetch()
    }

    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }


    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteTemplate(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    /**
     * Ein Template duplizieren.
     * @param {number} id Id des Templates das dupliziert wird.
     */
    const handleDuplicate = async( id ) => {
        const answer = await duplicteTemplate(id, apikey)
        refetch()
        if ( answer ){
            enqueueSnackbar('Duplikat wurde erstellt.', {variant: "success"})
            handleEdit ( answer )
        }else{
            enqueueSnackbar('Duplikat konnte nicht erstellt werden. Ein Fehler ist aufgetretten.', {variant: "error"})
        }
    }


    //Beim Laden eines Bilds! Kontrolle der Dimensionen
    const handleImageLoad = ( { target:img } ) => {
        let errmsg = null
        if (img.naturalHeight > 200 || img.naturalWidth > 200){
            errmsg = `Dimensionen sollten nicht grösser als 200x200px sein! Aktuell: ${img.naturalWidth}x${img.naturalHeight}`
        }
        setErrors(prev => ({
            ...prev,
            image_id: errmsg
        }))
    }


    //Ein ImageDialog zur Auswahl eines Betruer-Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            setValues(prev =>({
                ...prev,
                image_id: result.id,
                image_path: result.path,
                image_alt: result.alt
            }))
        }
        setimageDialogOpen(false)
    }
    

    //Den Drawer (rechte Seite) schliessen
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }

    return (
        <Container>
            <Typography variant="h1">
                Templates
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                            <AddIcon />
                        </IconButton>
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => handleEdit(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }


            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >

                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Template ändern...'}
                        { mode === "create" && 'Neues Template anlegen...'}
                    </Typography>


                    <TabContext value={ selectedTab }>
                        <TabList onChange={(e,n) => setSelectedTab(n)}>
                            <Tab value='form' label='Eingabe' />
                            <Tab value='entries' disabled={ mode === 'create' } label='Einträge' />
                        </TabList>
                        <TabPanel value='form'>
                            <Paper
                                elevation={0}
                                component="form"
                            >
                                <Stack spacing={4}>
                                    <Stack spacing={2} alignItems='flex-start'>
                                        { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                        <TextField
                                            variant='standard'
                                            label='Name'
                                            id='name'
                                            required
                                            fullWidth
                                            helperText={ errors.name }
                                            error= { errors.name ? true : false  }
                                            value={ values.name ? values.name : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    name: e.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Info'
                                            id='info'
                                            required
                                            fullWidth
                                            helperText={ errors.info }
                                            error= { errors.info ? true : false  }
                                            value={ values.info ? values.info : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    info: e.target.value
                                                }))
                                            }}
                                        />
                                        <Stack direction='row'>
                                            <Button
                                                onClick={() => {
                                                setimageDialogOpen(true)
                                            }}>
                                                { values.image_path ?
                                                    <Tooltip
                                                        title={ 
                                                            <>
                                                            <p>{values.image_name}</p>
                                                            <p>{values.image_description}</p>
                                                            <Button variant='contained' href={'/files/' + values.image_id}>go to</Button>
                                                            </>
                                                        }
                                                    >
                                                        <img
                                                            onLoad={(e) => handleImageLoad(e)}
                                                            src={rootPath + values.image_path}
                                                            alt={values.image_alt}
                                                            style={{maxHeight: 50, maxWidth: 100}}
                                                        />
                                                    </Tooltip>
                                                : 
                                                    <Skeleton variant="rounded" width={50} height={50} />
                                                }
                                            </Button>
                                            { errors.image_id && 
                                                <Alert severity='warning'>{errors.image_id}</Alert>
                                            }
                                        </Stack>

                                        <SourceTextfield
                                            label="Titel"
                                            required={false}
                                            fullWidth
                                            value={ values.title_source }
                                            helperText={ errors.title_id }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    title_source: n
                                                }))
                                            }}
                                        />
                                        <SourceTextfield
                                            label="Beschreibung"
                                            required={false}
                                            fullWidth
                                            value={ values.description_source }
                                            helperText={ errors.description_id }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    description_source: n
                                                }))
                                            }}
                                        />

                                    </Stack>
                                    <Stack direction='row' spacing={1}>
                                        <Button variant='contained' type='submit'
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                const res = await handleCheck()
                                                res && handleSave()                                        
                                            }}
                                        >Save</Button>
                                        <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                        { mode==='edit' &&
                                            <Button variant='contained' color="warning"
                                                onClick={() => {
                                                    handleDelete( values.id )
                                                }}
                                            >Delete</Button>
                                        }

                                        <Button
                                                variant="contained"
                                                disableElevation
                                                onClick={(e) => setEditMenuAnchor(e.currentTarget)}
                                                endIcon={<KeyboardArrowDownIcon />}
                                        >Options</Button>


                                        <Menu
                                            anchorEl={ editMenuAnchor }
                                            open={ editMenuAnchor ? true : false }
                                            onClose={() => setEditMenuAnchor(null)}
                                        >
                                            <MenuList>
                                                { mode==='edit' &&
                                                    <MenuItem
                                                        onClick={() => {
                                                            setEditMenuAnchor(null)  //Contextmenü schliessen
                                                            handleDuplicate( values.id )
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <FileCopyIcon fontSize='small'/>
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            Duplizieren
                                                        </ListItemText>
                                                    </MenuItem>
                                                }
                                            </MenuList>
                                        </Menu>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </TabPanel>
                        <TabPanel value='entries'>
                            <TemplateEntries template_id={values.id} apikey={apikey} />
                        </TabPanel>
                    </TabContext>
                </div>
            </Drawer>

            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Templates wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>

            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />

        </Container>
     );
}
 
export default Templatespage;