import { CssBaseline, Box, Container, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery } from "@mui/material"
import { useContext, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import SearchIcon from '@mui/icons-material/Search'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthContext } from '../components/AuthProvider'


const Home = () => {
    const [ go, setGo] = useState('')
    const navigate = useNavigate()
    const prefersDarkMode  = useMediaQuery('(prefers-color-scheme: dark)')
    const { apikey, isAdmin, user: operator } = useContext(AuthContext)

    const theme = useMemo(
        () =>
          createTheme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
            },
          }),
        [prefersDarkMode],
    );    

    const handleSubmit = (e) => {
        e.preventDefault()
        navigate('/' + go)
    }


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container className="home">
                <Box sx={{margin: 'auto 0', marginTop: '30px'}} display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                    <img width='32px' height='32px' src="/favicon-32x32.png" alt="my-drive.id Logo" style={{opacity: '0.5'}}/>
                    <Typography sx={{textAlign: 'center', ml: 1, fontSize: '0.7rem', color: '#d3d3d3'}}>powered by my-drive.id</Typography>
                </Box>

                <Stack direction='row' justifyContent='center' alignItems="baseline" sx={{mt: 10}}>
                    <Typography
                        variant='h2'
                        component='h1'
                        sx={{
                            letterSpacing: {xs: 10, md: 15},
                            fontWeight: {xs: 300, sm: 100, md: 100},
                            fontSize: {xs: 46, sm: 68, md: 86 }
                        }}
                    > <nobr>my-drive.</nobr></Typography>
                    <Typography
                        variant='h3'
                        component='h1'
                        sx={{
                            letterSpacing: 10,
                            fontWeight: {xs: 700, md: 500},
                            fontSize: { xs: 23, sm: 34, md: 43 }
                        }}
                    >id</Typography>
                </Stack>
                <Stack component='form' direction='row' justifyContent='center' sx={{mt: 3}} onSubmit={ handleSubmit }>
                    <TextField
                        value={ go }
                        onChange={(e) => setGo(e.target.value)}
                        autoComplete="off"
                        sx={{
                            input: {textAlign: 'center', fontSize: 22, marginRight: '50px'},
                            '& fieldset': { borderRadius: 5 },
                            width: 500,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <IconButton type='submit'>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
            </Container>
        </ThemeProvider>
     );
}
 
export default Home;