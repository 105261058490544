import { coreFetch } from './dbCoreFunc'
import { createTemplateentry, fetchTemplateentriesFromTemplate } from './dbTemplateentriesFunc'
import { getApiPath } from "./coreFunc"


//Alle Templates abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Block als results
export const fetchTemplates = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'templates/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Template abfragen mit einer id
//id: id des Templates das abgefragt werden soll.
//return: ein Object, mit status, message, und dem Template als results
export const fetchTemplate = async ( id ) => {
    var uri = await getApiPath() + 'templates/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neues Template erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createTemplate(name, info, image_id, title_id, description_id, apikey){
    const payload = {
        name: name,
        info: info,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templates/', 'POST', headers, JSON.stringify(payload))
}


//Ein Template ändern.
//id:   id des Teplates die geändert werden soll
//new{x}: die neuen Werte des Templates
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editTemplate(id, name, info, image_id, title_id, description_id, apikey){
    const payload = {
        name: name,
        info: info,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templates/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Template löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteTemplate(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templates/' + id, 'DELETE', headers)
}


//Ein neues Template aus einem bestehenden Block erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createTemplateFromBlock(block_id, name, info, apikey){
    const payload = {
        name: name,
        info: info
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templates/?fromBlock=' + block_id, 'POST', headers, JSON.stringify(payload))
}


/**
 * Ein Template duplizieren
 * @param {number} id Id des Templates das kopiert wird
 * @param {string} apikey API-Key für den Zugriff auf die API
 * @returns {number} Wenn alles geklappt hat, die ID des neuen Templates, ansonsten Null
 */
export async function duplicteTemplate(id, apikey){
    let answer = await fetchTemplate( id )
    if(!answer || answer.status !==1){ return null }
    answer = await createTemplate( answer.results.name, answer.results.info, answer.results.image_id, answer.results.title_id, answer.results.description_id, apikey)
    if(!answer || answer.status !==1){ return null }
    const newId = answer.id
    //Entries kopieren.
    answer = await fetchTemplateentriesFromTemplate( id )
    if(!answer || answer.status !==1){ return null }
    const arr = answer.results
    let error = false
    for (let i = 0; i < arr.length; i++) {
        const e = arr[i];
        answer = await createTemplateentry(newId, e.group_id, e.caption_id, e.content_id, e.link_id, apikey)        
        if(!answer || answer.status !==1){ error = true }        
    }
    if(error === true){ return null }else{ return newId }
}