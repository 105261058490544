import PropTypes from 'prop-types'
import { Tooltip, Skeleton, Alert, Button, DialogTitle, DialogContent, DialogActions, Dialog, Stack, Box, Typography } from '@mui/material'
import { fetchTemplate } from '../includes/dbTemplatesFunc';
import { fetchTemplateentriesFromTemplate } from '../includes/dbTemplateentriesFunc';
import { useEffect, useState } from 'react'
import SourceTextfield from './SourceTextfield';
import ImageDialog from './ImageDialog';
import { createBlock } from '../includes/dbBlocksFunc';
import { createEntry, editEntry } from '../includes/dbEntriesFunc';
import { checkSource } from '../includes/dbSourcesFunc'
import { getRootPath } from '../includes/coreFunc'



/**
 *  Wenn im Dossier ein Block von einem Template erstellt werden soll, wird als erstes der TemplateSelectDialog eingeblendet und dann dieser Dialog um die einzelnen Entries zu vervollständigen
 */
function TemplateCompleteDialog(props) {
    const { onClose, open, targetDossierId, templateId, apikey, ...other } = props;
    const [ errors, setErrors ] = useState({})
    const [ values, setValues ] = useState(null)
    const [ entries, setEntries ] = useState(null)
    const [ step, setStep ] = useState(-1)
    const [ imageDialogOpen, setimageDialogOpen ] = useState(false)
    const [ blockId, setBlockId ] = useState(null)
    const [rootPath, setRootPath] = useState('')
    

    useEffect(() => {
        (async () => {
            if (open && templateId) {
                //Template und Template-Entries laden
                let answer = await fetchTemplate( templateId )
                if( answer && answer.status === 1){
                    setValues(answer.results)
                    //Entries laden
                    answer = await fetchTemplateentriesFromTemplate ( templateId )
                    if( answer && answer.status === 1){
                        setEntries( answer.results )
                    }else{
                        setErrors( {err: 'Template-Einträge konnte nicht geladen werden.'} )
                    }
                }else{
                    setErrors( {err: 'Template konnte nicht geladen werden.'} )
                }
                setStep( -1 )
            }
            setRootPath(await getRootPath())
        })()
    }, [open, templateId]);


    const handleClose = ( result ) => {
        onClose(result ? result : false);
    };


    /**
     * Zum nächsten Step. Step -1 ist Eingabe der der Block Daten, danach kommen alle Entries des Templates.
     * @param {number} step Der aktuelle Step
     */
    const handleNext = async ( step ) => {

        if( step === -1 ){
            //Block erstellen.

            //Sources kontrollieren, falls notwendig neu anlegen:
            if(values.title_source){
                let answer = await checkSource(values.title_source, apikey)
                if(answer){
                    values.title_id = answer
                }else{
                    setErrors( {err: 'Title-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.title_id=null
            }
            if(values.description_source){
                let answer = await checkSource(values.description_source, apikey)
                if(answer){
                    values.description_id = answer
                }else{
                    setErrors( {err: 'Beschreibungs-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.description_id=null
            }
            const answer = await createBlock( targetDossierId, values.image_id, values.title_id, values.description_id, apikey )
            if( answer && answer.status === 1 ){
                setBlockId(answer.id)
                step++
            }else{
                setErrors({err: 'Programmfehler. Block konnte nicht erstellt werden.' + answer.message})
            }
        }else if( step < entries.length ){
            //Entry erstellen, ändern

            //Sources kontrollieren, falls notwendig neu anlegen:
            if(values.group_source){
                let answer = await checkSource(values.group_source, apikey)
                if(answer){
                    values.group_id = answer
                }else{
                    setErrors( {err: 'Gruppe-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.group_id=null
            }
            if(values.caption_source){
                let answer = await checkSource(values.caption_source, apikey)
                if(answer){
                    values.caption_id = answer
                }else{
                    setErrors( {err: 'Bezeichnungs-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.caption_id=null
            }
            if(values.content_source){
                let answer = await checkSource(values.content_source, apikey)
                if(answer){
                    values.content_id = answer
                }else{
                    setErrors( {err: 'Inhalt-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.content_id=null
            }
            if(values.link_source){
                let answer = await checkSource(values.link_source, apikey)
                if(answer){
                    values.link_id = answer
                }else{
                    setErrors( {err: 'Link-Source konnte nicht korrekt gespeichert werden.' + answer.message} )
                    return false
                }
            }else{
                values.link_id=null
            }

            //Es ist möglich, dass zurück-navigiert wurde, und dieses Entry schon gespeichert wurde, dann hat Values den entryId-Eintrag. In diesem Fall nicht neu anlegen, sondern ändern.
            if( !values.entryId ){
                //Entry muss angelegt werden.
                const answer = await createEntry( blockId, values.group_id, values.caption_id, values.content_id, values.link_id, apikey )
                if( answer && answer.status === 1 ){
                    entries[step] = values
                    entries[step].entryId = answer.id
                    step++
                }else{
                    setErrors({err: 'Programmfehler. Block konnte nicht erstellt werden.' + answer.message})
                }
            }else{
                //Entry wird geändert.
                const answer = await editEntry(values.entryId, blockId, values.group_id, values.caption_id, values.content_id, values.link_id, apikey )
                if( answer && answer.status === 1 ){
                    entries[step] = values
                    step++
                }else{
                    setErrors({err: 'Programmfehler. Block konnte nicht gespeichert werden.' + answer.message})
                }
            }
        }

        //Die Entry Daten des Steps in die Values laden:
        if(step < entries.length){
            setValues(entries[step])
        }

        setStep(step)
    }


    /**
     * Zum vorhergehenden Entry zurück. Geht nur bis 0, -1 also die Eingabe des Blocks ist nicht möglich.
     * @param {number} step Der aktuelle Step
     */
    const handlePrevious = ( step ) => {
        if( step === null || step === 0){return false}
        
        step--
        setValues(entries[step])
        setStep(step)
    }


    /**
     * Den aktuellen step überspringen. Funktioniert nicht bei -1. Also nur bei Entries
     * @param {number} step Der aktuelle Step
     */
    const handleSkip = ( step ) => {
        if( step === null || step === -1){ return false}
        step++
        //Die Entry Daten des Steps in die Values laden:
        if(step < entries.length){
            setValues(entries[step])
        }
        setStep(step)
    }

    
    /**
     * Kontrolliert ob die Eingaben ok sind.
     * @param {number} step Gibt an, was kontrolliert wird: -1 sind die Blockdaten, 0...n sind die Eingaben für Entries
     * @returns True wenn kein Fehler, ansonsten False
     */
    const handleCheck = ( step ) => {
        let collect = {}
        
        if( step === -1){
            //Block Daten
            if(!values.image_id){
                collect.image_id = "Bitte ein Bild auswählen."
            }
    
            if(!values.title_source){
                collect.title_id = "Titel eingeben."
            }
    
            if(!values.description_source){
                collect.description_id = "Beschreibung darf nicht leer sein."
            }
        }else{
            //Entry Daten
            if(!values.group_source){
                collect.group_id = "Feld darf nicht leer sein."
            }
    
            if(!values.caption_source){
                collect.caption_id = "Bezeichnung eingeben."
            }
    
            if(!values.content_source){
                collect.content_id = "Inhalt muss eingetragen werden."
            }
        }
        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    
    /**
     * Beim Laden eines Bilds! Kontrolle der Dimensionen 
     * @param {*} param0 
     */
    const handleImageLoad = ( { target:img } ) => {
        let errmsg = null
        if (img.naturalHeight > 200 || img.naturalWidth > 200){
            errmsg = `Dimensionen sollten nicht grösser als 200x200px sein! Aktuell: ${img.naturalWidth}x${img.naturalHeight}`
        }
        setErrors(prev => ({
            ...prev,
            image_id: errmsg
        }))
    }


    /**
     * Ein ImageDialog zur Auswahl eines Betruer-Bildes wurde geschlossen. 
     * 
     * @param {object} result Als Result des Dialogs wird entweder eine Object eines Bildes (file) oder null angegeben.
     * 
     */
    const handleImageDialogClose = ( result ) => {
        if(result){
            setValues(prev =>({
                ...prev,
                image_id: result.id,
                image_path: result.path,
                image_alt: result.alt
            }))
        }
        setimageDialogOpen(false)
    }


    return (
        <>
        <Dialog
            className='templates-dialog'
            maxWidth='80%'
            sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%' } }}
            open={open}
            {...other}
        >
        <DialogTitle>
            <Typography variant='h4' component='span'>{values ? values.name : 'Template'}</Typography>
            <Typography variant='h5' component='span' sx={{marginLeft: 2}}>{values ? values.info : ""}</Typography>
            <Typography variant='subtitle1' component='span' sx={{marginLeft: 2}}>{blockId ? "Block: " + blockId : ""}</Typography>
        </DialogTitle>
        <DialogContent dividers>
            { errors.err &&
                <Alert severity='warning'>
                    {errors.err}
                </Alert>
            }
            { values && step === -1 &&
                <Box sx={{padding: 3, marginBottom: 2}}>
                    <Stack direction='row'>
                        <Button
                            onClick={() => {
                            setimageDialogOpen(true)
                        }}>
                            { values.image_path ?
                                <Tooltip
                                    title={
                                        <>
                                        <p>{values.image_name}</p>
                                        <p>{values.image_description}</p>
                                        </>
                                    }
                                >
                                    <img
                                        onLoad={(e) => handleImageLoad(e)}
                                        src={rootPath + values.image_path}
                                        alt={values.image_alt}
                                        style={{maxHeight: 50, maxWidth: 100}}
                                    />
                                </Tooltip>
                            : 
                                <Skeleton variant="rounded" width={50} height={50} />
                            }
                        </Button>
                        { errors.image_id && 
                            <Alert severity='warning'>{errors.image_id}</Alert>
                        }
                    </Stack>

                    <SourceTextfield
                        label="Titel"
                        required={true}
                        fullWidth
                        value={ values.title_source }
                        helperText={ errors.title_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                title_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Beschreibung"
                        required={true}
                        fullWidth
                        value={ values.description_source }
                        helperText={ errors.description_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                description_source: n
                            }))
                        }}
                    />
                </Box>

            }
            { values && entries && step > -1 && step < entries.length &&
                <Stack spacing={2} sx={{padding: 3, marginBottom: 2}}>
                    <Stack direction='row'>
                        <Typography sx={{marginBottom: 2}} component='span'>{step+1}/{entries.length}</Typography>
                        <Typography sx={{marginLeft: 2}}  component='span'>{ values.entryId ? "Entry-Id: " + values.entryId : ""}</Typography>
                    </Stack>
                    <SourceTextfield
                        label="Gruppe"
                        required={true}
                        fullWidth
                        value={ values.group_source }
                        helperText={ errors.group_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                group_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Bezeichnung"
                        required={true}
                        fullWidth
                        value={ values.caption_source }
                        helperText={ errors.caption_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                caption_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Inhalt"
                        required={true}
                        fullWidth
                        value={ values.content_source }
                        helperText={ errors.content_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                content_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Link"
                        required={false}
                        fullWidth
                        value={ values.link_source }
                        helperText={ errors.link_id }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                link_source: n
                            }))
                        }}
                    />
                </Stack>
            }
            { entries && step === entries.length && 
                <Typography>Keine weiteren Entries.</Typography>
            }
        </DialogContent>
        <DialogActions>
            { entries && step === entries.length ?
                <>
                <Button onClick={() => handleClose(true)}>Fertig</Button>
                </>
            :
                <>
                <Button disabled={ (values && values.entryId ? true : false) || step === -1 } onClick={() => handleSkip(step)}>Überspringen</Button>
                <Button disabled={ step < 1 } onClick={() => handlePrevious(step)}>Zurück</Button>
                <Button autoFocus onClick={() => {
                if(handleCheck( step )) { handleNext( step ) }
                }} >Weiter</Button>
                <Button onClick={handleClose}>Cancel</Button>
                </>
            }





        </DialogActions>
        </Dialog>

        <ImageDialog
            // sx={{zIndex: 1400}}
            keepMounted
            open={ imageDialogOpen }
            onClose={(e) => handleImageDialogClose(e)}
        />
        </>
    );
    }
    
    TemplateCompleteDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        targetDossierId: PropTypes.number.isRequired,
        templateId: PropTypes.number.isRequired,
        apikey: PropTypes.string.isRequired
    };

    export default TemplateCompleteDialog;