import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Contacts abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und den contacts als results
export const fetchContacts = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'contacts/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Contact abfragen mit einer id
//id: id des contacts die abgefragt werden soll.
//return: ein Object, mit status, message, und dem contact als results
export const fetchContact = async ( id ) => {
    var uri = await getApiPath() + 'contacts/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Contact erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createContact(name, emailquote, emailorder, advisorimage_id, advisorname, advisoremail, advisorphone, companyimage_id, companyname, companyaddress, companyzip, companycity, companycountry, companyphone, companyemail, companyweb, apikey){
    const payload = {
        name: name,
        emailquote: emailquote,
        emailorder: emailorder,
        advisorimage_id: advisorimage_id,
        advisorname: advisorname,
        advisoremail: advisoremail,
        advisorphone: advisorphone,
        companyimage_id: companyimage_id,
        companyname: companyname,
        companyaddress: companyaddress,
        companyzip: companyzip,
        companycity: companycity,
        companycountry: companycountry,
        companyphone: companyphone,
        companyemail: companyemail,
        companyweb: companyweb
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'contacts/', 'POST', headers, JSON.stringify(payload))
}


//Ein Contact ändern.
//id:   id des Contacts die geändert werden soll
//new{x}: die neuen Werte des Contacts
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editContact(id, name, emailquote, emailorder, advisorimage_id, advisorname, advisoremail, advisorphone, companyimage_id, companyname, companyaddress, companyzip, companycity, companycountry, companyphone, companyemail, companyweb, apikey){
    const payload = {
        name: name,
        emailquote: emailquote,
        emailorder: emailorder,
        advisorimage_id: advisorimage_id,
        advisorname: advisorname,
        advisoremail: advisoremail,
        advisorphone: advisorphone,
        companyimage_id: companyimage_id,
        companyname: companyname,
        companyaddress: companyaddress,
        companyzip: companyzip,
        companycity: companycity,
        companycountry: companycountry,
        companyphone: companyphone,
        companyemail: companyemail,
        companyweb: companyweb
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'contacts/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Contact löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteContact(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'contacts/' + id, 'DELETE', headers)
}

//Gibt an, ob ein name schon existiert.
//name: name die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf der name identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn der username schon existiert, ansonsten false
export async function exitsContact(name, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', name)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'contacts/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}
