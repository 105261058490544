import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Stack, Typography, Card, CardHeader, IconButton, Box, CardContent } from "@mui/material";
import { useState } from 'react';
import { useQuery } from "react-query";
import { checkSource } from '../includes/dbSourcesFunc'
import SourceTextfield from '../components/SourceTextfield'
import { useSnackbar } from 'notistack'
import { createEntry, deleteEntry, editEntry, fetchEntriesFromBlock } from '../includes/dbEntriesFunc'
import PropTypes from 'prop-types'


/**
 * Auflistung der Entries im EditBlockDialog
 * @returns 
 */
const EditEntries = ( { blockId, apikey } ) => {
    const { data, refetch} = useQuery(['entries', blockId], () =>  fetchEntriesFromBlock(blockId))
    const [mode, setMode] = useState('')
    const [values, setValues] = useState(null)
    const [errors, setErrors] = useState({})
    const { enqueueSnackbar } = useSnackbar()

  

    const handleNew = () => {
        setMode('create')
        setValues({ block_id: blockId, group_id: null, group_source: '', caption_id: null, caption_source: '', content_id: null, content_source: '', link_id: null, link_source: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
        setErrors({})
    }


    const handleEdit = ( id ) => {
        const value = data.results.filter(v => v.id === id)[0]
        setMode('edit')
        setValues(value)
        setErrors({})
    }


    const handleCheck = () => {
        //Werte überprüfen:
        let collectErrors = {}

        if(!values.group_source){
            collectErrors.group_id = 'Gruppe eingeben.'
        }
        if(!values.caption_source){
            collectErrors.caption_id = 'Bezeichnung darf nicht leer sein.'
        }
        if(!values.content_source){
            collectErrors.content_id = 'Inhalt darf nicht leer sein.'
        }


        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    const handleSave = async () => {
        //Sources kontrollieren, falls notwendig neu anlegen:
        if(values.group_source){
            let answer = await checkSource(values.group_source, apikey)
            if(answer){
                values.group_id = answer
            }else{
                enqueueSnackbar('Gruppen-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            values.group_id=null
        }

        if(values.caption_source){
            let answer = await checkSource(values.caption_source, apikey)
            if(answer){
                values.caption_id = answer
            }else{
                enqueueSnackbar('Bezeichnung-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            values.caption_id=null
        }

        if(values.content_source){
            let answer = await checkSource(values.content_source, apikey)
            if(answer){
                values.content_id = answer
            }else{
                enqueueSnackbar('Inhalt-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            values.content_id=null
        }

        if(values.link_source){
            let answer = await checkSource(values.link_source, apikey)
            if(answer){
                values.link_id = answer
            }else{
                enqueueSnackbar('Link-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            values.link_id=null
        }


        if(mode === 'create'){
            //Neues Entry erstellen.
            const res = await createEntry(values.block_id, values.group_id, values.caption_id, values.content_id, values.link_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Entry erfolgreich angelegt.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Value ändern.
            const res = await editEntry(values.id, values.block_id, values.group_id, values.caption_id, values.content_id, values.link_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Entry erfolgreich geändert.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
      
    }

    
    const handleCancelEdit = () => {
        setMode('')
        setValues(null)
        setErrors({})
    }


    const handleDelete = async ( id ) => {
        const result = await deleteEntry(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
        }else{
            enqueueSnackbar("Entry erfolgreich gelöscht.", {variant: "success"})
            refetch()
        }  
    }




    return (
        <Box>
            <Stack direction='row' spacing={2} mb={2}>
                <IconButton onClick={() => {handleNew()}}><AddIcon color='primary' fontSize='small' /></IconButton>
                <IconButton onClick={() => {refetch()}}><RefreshIcon color='primary' fontSize='small' /></IconButton>
            </Stack>
            { mode === 'create' && 
                <Card sx={{mb: 5, width: '500px'}}>
                    <CardHeader
                        title={<Typography variant='h4' color='primary'>Neuer Eintrag</Typography>}
                        action={
                            <>
                            <IconButton
                                onClick={() => {
                                    const check = handleCheck()
                                    check && handleSave()
                                }}
                            ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                            <IconButton
                                onClick={() => {
                                    handleCancelEdit()
                                }}
                            ><CancelOutlinedIcon fontSize='small' /></IconButton>
                            </>
                        }
                    />
                    <CardContent>
                        <Stack spacing={1} sx={{pb: 3}}>
                            <SourceTextfield
                                label="Gruppe"
                                required={true}
                                fullWidth
                                value={ values.group_source }
                                helperText={ errors.group_id }
                                onChange={(n) => {
                                    setValues(prev =>({
                                        ...prev,
                                        group_source: n
                                    }))
                                }}
                            />
                            <SourceTextfield
                                label="Bezeichnung"
                                required={true}
                                fullWidth
                                value={ values.caption_source }
                                helperText={ errors.caption_id }
                                onChange={(n) => {
                                    setValues(prev =>({
                                        ...prev,
                                        caption_source: n
                                    }))
                                }}
                            />
                            <SourceTextfield
                                label="Inhalt"
                                required={true}
                                fullWidth
                                value={ values.content_source }
                                helperText={ errors.content_id }
                                onChange={(n) => {
                                    setValues(prev =>({
                                        ...prev,
                                        content_source: n
                                    }))
                                }}
                            />
                            <SourceTextfield
                                label="Link"
                                required={false}
                                fullWidth
                                value={ values.link_source }
                                helperText={ errors.link_id }
                                onChange={(n) => {
                                    setValues(prev =>({
                                        ...prev,
                                        link_source: n
                                    }))
                                }}
                            />
                        </Stack>                        
                    </CardContent>
                </Card>
            }

            { data && data.results && data.results.length > 0 ? (
                // <Stack spacing={1}>
                <Stack
                    direction='row'
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexWrap="wrap"
                >
                    {data.results.sort((a,b) => (a.group_source > b.group_source) ? 1 : ((b.group_source > a.group_source) ? -1 : 0)).map(value =>
                        <Card key={value.id} sx={{width: '300px', margin: 1}}>
                            <CardHeader
                                title={
                                    <Stack direction='row'>
                                        <Typography variant='body2' color='secondary'>{value.group_source}</Typography>
                                    </Stack>
                                }
                                action={ <Stack direction='row'>
                                    {mode === 'edit' && values && values.id === value.id ? (
                                        <>
                                        <IconButton
                                            onClick={() => {
                                                const check = handleCheck()
                                                check && handleSave()
                                            }}
                                        ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                                        <IconButton
                                            onClick={() => {
                                                handleCancelEdit()
                                            }}
                                        ><CancelOutlinedIcon fontSize='small' /></IconButton>
                                        </>
                                    ) : (
                                        <IconButton onClick={() => {handleEdit(value.id)}}><EditIcon fontSize='small' /></IconButton>
                                    )}


                                    <IconButton
                                        color='warning'
                                        onClick={async () => handleDelete(value.id)}
                                    ><DeleteIcon fontSize='small'/></IconButton>
                                </Stack> }
                            />
                            <CardContent>
                                {mode === 'edit' && values && values.id === value.id ? (
                                    <Stack spacing={1} sx={{pb: 3}}>
                                        <SourceTextfield
                                            label="Gruppe"
                                            required={true}
                                            fullWidth
                                            value={ values.group_source }
                                            helperText={ errors.group_source }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    group_source: n
                                                }))
                                            }}
                                        />
                                        <SourceTextfield
                                            label="Bezeichnung"
                                            required={true}
                                            fullWidth
                                            value={ values.caption_source }
                                            helperText={ errors.caption_source }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    caption_source: n
                                                }))
                                            }}
                                        />
                                        <SourceTextfield
                                            label="Inhalt"
                                            required={true}
                                            fullWidth
                                            value={ values.content_source }
                                            helperText={ errors.content_source }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    content_source: n
                                                }))
                                            }}
                                        />
                                        <SourceTextfield
                                            label="Link"
                                            required={false}
                                            fullWidth
                                            value={ values.link_source }
                                            helperText={ errors.link_source }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    link_source: n
                                                }))
                                            }}
                                        />                                                                                                                        
                                    </Stack>
                                ) : (
                                    <Stack direction='row' justifyContent="space-between">
                                        <Typography>{value.caption_source}</Typography>
                                        <Stack direction='row' spacing={0.2}>
                                            <a href={value.link_source} target='_blank' rel="noreferrer">
                                                <Typography fontWeight='600' color='primary'>{value.content_source}</Typography>
                                            </a>
                                        </Stack>
                                    </Stack>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Stack>                
            ) : (
                <Typography>Noch keine Daten</Typography>
            )}
        </Box>
     );
}


EditEntries.propTypes = {
    blockId: PropTypes.number.isRequired,
    apikey: PropTypes.string.isRequired
};

export default EditEntries;