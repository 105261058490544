import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Box, Autocomplete } from '@mui/material'
import { fetchSources, sourceStatus } from "../includes/dbSourcesFunc"
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import AddBoxIcon from '@mui/icons-material/AddBox'
import TranslateIcon from '@mui/icons-material/Translate'
import ErrorIcon from '@mui/icons-material/Error'
import debounce from "lodash.debounce"
import { useCallback } from 'react'

  
function SourceTextfield(props) {
    const { onChange, update, fullWidth, helperText, required, value: valueProp, ...other } = props;
    const [ options, setOptions ] = useState([])
    const [ value, setValue ] = useState(valueProp)
    const [ status, setStatus] = useState(0)
    //const wait = useRef(false)

    useEffect(() => {
        if(valueProp){
            setValue(valueProp)
        }else{
            setValue('')
        }
        if(status === 0 && !required){
            setStatus(-1)
        }else if(status === -1 && required){
            setStatus(0)
        }
        // eslint-disable-next-line
    }, [valueProp, required]);

    useEffect(() => {
        if(onChange){
            onChange(value)
        }
        // eslint-disable-next-line
    },[value])


    useEffect(() => {
        handleOptions(value)
        // eslint-disable-next-line
    },[ update ])


    //Eingabefeld hat sich geändert.
    const handleChange = async ( newValue ) => {
        setValue(newValue)
        handleOptions(newValue)
    }


    //Wenn der Text geändert wird, dann wird jeweils 400ms gewartet, bevor die Options und der Status geändert wird.
    // eslint-disable-next-line
    const handleOptions = useCallback(
        debounce(async (value) => {
            // if(value !== ''){
            if(value){
                const answer = await fetchSources(10, value)
                if(answer && answer.status === 1){ setOptions(answer.results) }
                const stat = await sourceStatus(value)
                if(stat && stat.status === 1){
                    setStatus(stat.results)
                }else{
                    required ? setStatus(0) : setStatus(-1)
                }
            }else{
                required ? setStatus(0) : setStatus(-1)     //0, wenn ein Wert eingegeben werden muss, -1, wenn kein Wert angegeben werden muss
                setOptions([])
            }
        }, 400)
    ,[])


    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: fullWidth ? '100%' : null }}>
            <Autocomplete
                value={ value ? value : '' }
                freeSolo
                fullWidth={ fullWidth }
                options={ options}
                filterOptions={(x) => x}
                onOpen={async (e) => await handleChange(e.target.value)}
                onInputChange={async (e, v) => await handleChange(v)}
                getOptionLabel={(option) => option.source ?? option}
                isOptionEqualToValue={(option, value) => {
                    return option.source === value.source
                }}
                renderInput={(params) =>
                    <TextField
                        variant="standard"
                        {...params}
                        {...other}
                        error={ status === 0 || helperText != null}
                        helperText={ helperText }
                    />
                }
            />
            {status===0 && <ErrorIcon color="error"/>}
            {status===1 && <AddBoxIcon color="warning"/>}
            {status===2 && <TranslateIcon color="success"/>}
            {status===3 && <CheckBoxIcon color="success"/>}
        </Box>
    );
}
    
SourceTextfield.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    update: PropTypes.number,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string
};

SourceTextfield.defaultProps = {
    value: '',
    required: false,
    fullWidth: false
}


export default SourceTextfield;


// import { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
// import { TextField, Box, Autocomplete } from '@mui/material'
// import { fetchSources, sourceStatus } from "../includes/dbSourcesFunc"
// import CheckBoxIcon from '@mui/icons-material/CheckBox'
// import AddBoxIcon from '@mui/icons-material/AddBox'
// import TranslateIcon from '@mui/icons-material/Translate'
// import ErrorIcon from '@mui/icons-material/Error'
// import debounce from "lodash.debounce"
// import { useCallback } from 'react'

  
// function SourceTextfield(props) {
//     const { onChange, update, required, value: valueProp, ...other } = props;
//     const [ options, setOptions ] = useState([])
//     const [ value, setValue ] = useState(valueProp)
//     const [ status, setStatus] = useState(0)
//     //const wait = useRef(false)

//     useEffect(() => {
//         if(valueProp){
//             setValue(valueProp)
//         }else{
//             setValue('')
//         }
//         if(status === 0 && !required){
//             setStatus(-1)
//         }else if(status === -1 && required){
//             setStatus(0)
//         }
//         // eslint-disable-next-line
//     }, [valueProp, required]);

//     useEffect(() => {
//         if(onChange){
//             onChange(value)
//         }
//         // eslint-disable-next-line
//     },[value])


//     useEffect(() => {
//         handleOptions(value)
//         // eslint-disable-next-line
//     },[ update ])


//     //Eingabefeld hat sich geändert.
//     const handleChange = async ( newValue ) => {
//         setValue(newValue)
//         handleOptions(newValue)
//     }


//     //Wenn der Text geändert wird, dann wird jeweils 400ms gewartet, bevor die Options und der Status geändert wird.
//     // eslint-disable-next-line
//     const handleOptions = useCallback(
//         debounce(async (value) => {
//             if(value !== ''){
//                 const answer = await fetchSources(10, value)
//                 if(answer && answer.status === 1){ setOptions(answer.results) }
//                 const stat = await sourceStatus(value)
//                 if(stat && stat.status === 1){
//                     setStatus(stat.results)
//                 }else{
//                     required ? setStatus(0) : setStatus(-1)
//                 }
//             }else{
//                 required ? setStatus(0) : setStatus(-1)     //0, wenn ein Wert eingegeben werden muss, -1, wenn kein Wert angegeben werden muss
//                 setOptions([])
//             }
//         }, 400)
//     ,[])


//     return (
//         <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
//             <Autocomplete
//                 value={ value ? value : '' }
//                 freeSolo
//                 options={ options}
//                 filterOptions={(x) => x}
//                 onOpen={async (e) => await handleChange(e.target.value)}
//                 onInputChange={async (e, v) => await handleChange(v)}
//                 getOptionLabel={(option) => option.source ?? option}
//                 isOptionEqualToValue={(option, value) => {
//                     return option.source === value.source
//                 }}
//                 renderInput={(params) =>
//                     <TextField
//                         variant="standard"
//                         {...params}
//                         {...other}
//                     />
//                 }
//             />
//             {status===0 && <ErrorIcon color="error"/>}
//             {status===1 && <AddBoxIcon color="warning"/>}
//             {status===2 && <TranslateIcon color="success"/>}
//             {status===3 && <CheckBoxIcon color="success"/>}
//         </Box>
//     );
// }
    
// SourceTextfield.propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     required: PropTypes.bool,
//     update: PropTypes.number
// };

// SourceTextfield.defaultProps = {
//     value: '',
//     required: false
// }


// export default SourceTextfield;