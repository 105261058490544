
export const delay = t => new Promise(resolve => setTimeout(resolve, t));


export const waitFor = async (doc, id) => {

    for (let i = 0; i < 10; i++) {
        await delay(10)
        if(doc.getElementById(id)){
            return true
        }
    }
    return false
}


/**
 * Wandelt einen Text oder eine Zahl um in ein Boolean
 * @param {mixed} val 
 * @returns {boolean} True bei "1", "on", "yes" oder "true", ansonsten False
 */
export const parseBoolean = (val) => {
    const regex = /^\s*(true|1|on|yes)\s*$/i
    return regex.test(val)
}


let apiPath = null
export const getApiPath = async () =>{
    if( !apiPath ){
        const data = await fetch('/config/apiPath.txt')
            .then((response) => response.text())
            .then((t) => {
                return t
            })
            .catch((error) => {
                return {status: 0, message: error.message};
        })
        apiPath = data
    }
    return apiPath
}

let rootPath = null
export const getRootPath = async () =>{
    if( !rootPath ){
        const data = await fetch('/config/rootPath.txt')
            .then((response) => response.text())
            .then((t) => {
                return t
            })
            .catch((error) => {
                return {status: 0, message: error.message};
        })
        rootPath = data
    }
    return rootPath
}