import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle translations abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//source: hier kann eine id einer source angegeben werden, dann wird nur die translations dieser source zurückgegeben
//return: ein Object, mit status, message, und der source als results
export const fetchTranslations = async ( limit, source ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(source){ params.append('source',  source) }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'translations/' + (paramsStr !== '?' ? paramsStr : '')
    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Eine translation abfragen mit einer id
//id: id der translation die abgefragt werden soll.
//return: ein Object, mit status, message, und der source als results
export const fetchTranslation = async ( id ) => {
    var uri = await getApiPath() + 'translations/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Eine neue translation erstellen.
//{x}: die Werte der translation
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createTranslation(source_id, language_id, translation, apikey){
    const payload = {
        source_id: source_id,
        language_id: language_id,
        translation: translation
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'translations/', 'POST', headers, JSON.stringify(payload))
}


//Eine translation ändern.
//id:   id der translation die geändert werden soll
//{x}: die neuen Werte der translation
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editTranslation(id, source_id, language_id, translation, apikey){
    const payload = {
        source_id: source_id,
        language_id: language_id,
        translation: translation
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'translations/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Eine translation löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteTranslation(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'translations/' + id, 'DELETE', headers)
}

