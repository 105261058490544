import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import PreviewIcon from '@mui/icons-material/Preview'
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { FormGroup, FormControlLabel, Checkbox, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, Autocomplete, MenuItem, Select, FormControl, InputLabel, Tab, Card, CardHeader, Menu, MenuList, ListItemIcon, ListItemText, InputAdornment, Tooltip, CardActions } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchDossiers, fetchDossier, createDossier, editDossier, deleteDossier, mydriveidExitst, duplicateDossier} from '../includes/dbDossiersFunc'
import { deleteBlock, fetchBlocksFromDossier, moveBlockUp, moveBlockDown } from '../includes/dbBlocksFunc'
import { fetchBrands } from '../includes/dbBrandsFunc'
import { fetchContacts } from '../includes/dbContactsFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import debounce from "lodash.debounce"
import DuplicateDossierDialog from '../components/DuplicateDossierDialog'
import CopyBlockDialog from '../components/CopyBlockDialog'
import { createTemplateFromBlock } from '../includes/dbTemplatesFunc'
import TemplateSelectDialog from '../components/TemplateSelectDialog'
import CreateTemplateDialog from '../components/CreateTemplateDialog'
import EditBlockDialog from '../components/EditBlockDialog'
import TemplateCompleteDialog from '../components/TemplateCompleteDialog'
import { getRootPath } from '../includes/coreFunc'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'mydriveid', headerName: 'mydrive-id', width: 130 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'part', headerName: 'Artikel', width: 250 },
    { field: 'ordernumber', headerName: 'Bestellnummer', width: 100 },
    { field: 'quantity', headerName: 'Menge', width: 60 },
    { field: 'brand_name', headerName: 'Brand', width: 150 },
    { field: 'blocks_count', headerName: 'Blocks', width: 60 }
];


const Dossierspage = () => {
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(15)
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [brands, setBrands] = useState([])
    const [contacts, setContacts] = useState([])
    const [blocks, setBlocks] = useState([])
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const [errors, setErrors] = useState({})
    const [selectedTab, setSelectedTab] = useState('form')
    const [editMenuAnchor, setEditMenuAnchor] = useState(null)
    const [addBlockMenuAnchor, setAddBlockMenuAnchor] = useState(null)
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false)
    const [copyBlockDialogOpen, setCopyBlockDialogOpen] = useState(false)
    const [templateSelectDialogOpen, setTemplateSelectDialogOpen] = useState(false)
    const [editBlockDialogOpen, setEditBlockDialogOpen ] = useState(false)
    const [editBlockId, setEditBlockId] = useState(null)
    const [editBlockDossierId, setEditBlockDossierId] = useState(null)
    const [blockForTemplate, setBlockForTemplate ] = useState(null)
    const [templateId, setTemplateId] = useState(null)
    const [templateCompleteDialogOpen, setTemplateCompleteDialogOpen] = useState(false)
    const [rootPath, setRootPath] = useState('')
    const { id: dossierId } = useParams()   //ID aus der URL, wenn also der Dossier 84 aufgerufen werden soll.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['dosiers', rowsCount, search], () => fetchDossiers(rowsCount, search))
    const searchInput = useRef()


    useEffect(() => {
        (async () => {
            const brands = await fetchBrands()
            setBrands(brands.results)
            const contacts = await fetchContacts()
            setContacts(contacts.results)
            if(dossierId){
                //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
                handleEdit( dossierId )            
            }
            setRootPath(await getRootPath())
        })();
    // eslint-disable-next-line
    },[])


    //Eine Zeile in der Tabelle ausgewählt. Diesen Datensatz bearbeiten.
    const handleEdit = async (id) => {
        const row = await fetchDossier(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                if(brands){
                    const brand = brands.find(e => e.id === row.results.brand_id )
                    setSelectedBrand(brand)
                }
                if(contacts){
                    const contact = contacts.find(e => e.id === row.results.contact_id )
                    setSelectedContact(contact)
                }
                fetchBlocks( id ) //Die Blocks des Dossiers mit der id in die Variable blocks laden.
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Dossier mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }

    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({mydriveid: '', name: '', part: '', ordernumber: '', quantity: '', deliverydate: null, hidedetails: 0, brand_id: '', contact_id: '', hidequote: 0, hideorder: 0, hidecompany: 0, hideadvisor: 0 })  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setSelectedBrand(null)
            setSelectedContact(null)
            setErrors({})
            setSelectedTab('form')  //Es könnte sonst sein, dass noch der Blocks-Tab ausgewählt ist.
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleEdit gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }

    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async() => {
        let collect = {}
        let idcheck = /[^a-zA-Z0-9_.\-']/

        if(!values.mydriveid){
            collect.mydriveid = 'mydrive-id angeben.'
        }else if(idcheck.test(values.mydriveid)){
            collect.mydriveid = "Darf nur Zahlen, Buchstaben, '-', '_' oder '.' enthalten ."
        }else{
            if(await mydriveidExitst(values.mydriveid, values.id)){
                collect.mydriveid = 'mydrive-id ist schon vorhanden. Bitte eine andere wählen.'
            }
        }

        if(!values.name){
            collect.name = 'Name eingeben.'
        }

        if(!values.ordernumber){
            collect.ordernumber = 'Bestellnummer eingeben.'
        }

        if(!values.quantity){
            collect.quantity = 'Menge eintragen.'
        }

        if(selectedBrand === null){
            collect.brand_id = 'Es muss ein Brand ausgewählt werden.'
        }

        if(selectedContact === null){
            collect.contact_id = 'Es muss ein Kontakt ausgewählt werden.'
        }


        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        const brand_id = selectedBrand.id
        const contact_id = selectedContact.id

        if(mode === 'create'){
            //Neuen Dossier erstellen.
            const res = await createDossier(values.mydriveid, values.name, values.part, values.ordernumber, values.quantity, values.deliverydate, values.hidedetails, brand_id, contact_id, values.hidequote, values.hideorder, values.hidecompany, values.hideadvisor, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Dossier erfolgreich angelegt.", {variant: 'success'})
                refetch()
                await handleEdit(res.id)
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Dossier ändern.
            const res = await editDossier(values.id, values.mydriveid, values.name, values.part, values.ordernumber, values.quantity, values.deliverydate, values.hidedetails, brand_id, contact_id, values.hidequote, values.hideorder, values.hidecompany, values.hideadvisor, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Dossier erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Ein Dossier löschen
    const handleDelete = async (id) => {
        const result = await deleteDossier(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Dossier erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteDossier(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    /**
     * Einen Block im Dialog bearbeiten
     * @param {number} blockId Id des Block der bearbeitet werden soll
     */
    const handleEditBlock = ( blockId ) => {
        setEditBlockId(blockId)
        setEditBlockDossierId(null)
        setEditBlockDialogOpen(true)
    }


    /**
     * Einen neuen leeren Block erstellen
     * @param {number} dossierId Id des Block der bearbeitet werden soll
     */
    const handleCreateBlock = ( dossierId ) => {
        setEditBlockId(null)
        setEditBlockDossierId(dossierId)
        setEditBlockDialogOpen(true)
    }


    const handleMoveBlockUp = async (blockId) => {
        await moveBlockUp(blockId, apikey)
    }


    const handleMoveBlockDown = async (blockId) => {
        await moveBlockDown(blockId, apikey)
    }



    //Ein Dossier duplizieren. Als Values muss ein Object mit id des zu kopierenden Dossiers, die neue mydriveid, name, ordernumber, quantity.
    const handleDuplicate = async ( values ) => {
        if(values){
            const answer = await duplicateDossier( values.id, values.mydriveid, values.name, values.part, values.ordernumber, values.quantity, values.deliverydate, values.hidedetails,  values.brand_id, values.contact_id, values.hidequote, values.hideorder, values.hidecompany, values.hideadvisor , apikey )
            if(answer.status === 1){
                enqueueSnackbar("Duplikat erfolgreich erstellt.", {variant: "success"})
                refetch()
                handleEdit( answer.id )
            }else{
                enqueueSnackbar("Probleme beim Kopieren des Dossier. " + answer.message, {variant: "error"})
            }

        }else{
            //Cancel im Dialog
        }
    }


    //Aus einem Block ein Template erstellen
    const handleCreateTemplate = async ( block, name, info ) => {
        const answer = await createTemplateFromBlock(block.id, name, info, apikey)
        if(answer.status === 1){
            //navigate('/template/' + answer.id)
            enqueueSnackbar("Template erfolgreich erstellt.", {variant: "success"})
        }else{
            enqueueSnackbar("Probleme beim Erstellen eines Templates aus einem Block. " + answer.message, {variant: "error"})
        }
    }


    //Die Blocks des Dossiers mit der id in die Variable blocks laden.
    const fetchBlocks = async (id) => {
        const answer = await fetchBlocksFromDossier( id )
        answer ? setBlocks(answer.results) : setBlocks([])
    }


    return (
        <Container>
            <Typography variant="h1">
                Dossiers
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                        <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                            <AddIcon />
                        </IconButton>
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => handleEdit(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: '500px' }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Dossier ändern...'}
                        { mode === "create" && 'Neues Dossier anlegen...'}
                    </Typography>


                    <TabContext value={ selectedTab }>
                        <TabList onChange={(e,n) => setSelectedTab(n)}>
                            <Tab value='form' label='Eingabe' />
                            <Tab value='blocks' disabled={ mode === 'create' } label='Blocks' />
                        </TabList>
                        <TabPanel value='form'>
                            <Paper
                                elevation={0}
                                component="form"
                                autoComplete="off"
                            >
                                <Stack spacing={4}>
                                    <Stack spacing={2}>
                                        { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                        <TextField
                                            variant='standard'
                                            label='mydrive-id'
                                            id='mydriveid'
                                            required
                                            fullWidth
                                            helperText={ errors.mydriveid }
                                            error= { errors.mydriveid ? true : false  }
                                            value={ values.mydriveid ? values.mydriveid : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    mydriveid: e.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Name'
                                            id='name'
                                            required
                                            fullWidth
                                            helperText={ errors.name }
                                            error= { errors.name ? true : false  }
                                            value={ values.name ? values.name : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    name: e.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Artikelnummer'
                                            id='part'
                                            fullWidth
                                            helperText={ errors.part }
                                            error= { errors.part ? true : false  }
                                            value={ values.part ? values.part : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    part: e.target.value
                                                }))
                                            }}
                                        />                                        
                                        <TextField
                                            variant='standard'
                                            label='Bestellnummer'
                                            id='ordernumber'
                                            required
                                            helperText={ errors.ordernumber }
                                            error= { errors.ordernumber ? true : false  }
                                            fullWidth
                                            value={ values.ordernumber ? values.ordernumber : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    ordernumber: e.target.value
                                                }))
                                            }}                                    
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Menge'
                                            id='quantity'
                                            required
                                            helperText={ errors.quantity }
                                            error= { errors.quantity ? true : false  }
                                            fullWidth
                                            value={ values.quantity ? values.quantity : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    quantity: e.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Auslieferungsdatum'
                                            id='deliverydate'
                                            helperText={ errors.deliverydate }
                                            error= { errors.deliverydate ? true : false  }
                                            fullWidth
                                            value={ values.deliverydate ? values.deliverydate : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    deliverydate: e.target.value
                                                }))
                                            }}
                                        />                                        
                                        <FormGroup>
                                            <FormControlLabel label="Details (Bestellnummer, Menge) ausblenden" control={
                                                <Checkbox
                                                    id="hidedetails"
                                                    checked={ values.hidedetails === 1 ? true : false }
                                                    onChange={(e) => {
                                                        const newValue = e.target.checked === true ? 1 : 0
                                                        setValues(prev =>({
                                                            ...prev,
                                                            hidedetails: newValue
                                                        }))
                                                    }}
                                                />
                                            } />
                                        </FormGroup>                                        
                                        <Autocomplete
                                            id="brand"
                                            value={ selectedBrand }
                                            options={ brands }
                                            getOptionLabel={(option) => option.name ?? option}
                                            onChange={(e, brand) => {
                                                setSelectedBrand( brand )
                                                if(brand){
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hideadvisor: brand.hideadvisor,
                                                        hidecompany: brand.hidecompany,
                                                        hidedetails: brand.hidedetails,
                                                        hideorder: brand.hideorder,
                                                        hidequote: brand.hidequote
                                                    }))
                                                }
                                            }}
                                            renderInput={(params) => <TextField
                                                                        {...params}
                                                                        variant='standard'
                                                                        label="Brand"
                                                                        required
                                                                        helperText={ errors.brand_id }
                                                                        error={ errors.brand_id ? true : false }
                                                                    />}
                                        />
                                        <Autocomplete
                                            id="contact"
                                            value={ selectedContact }
                                            options={ contacts }
                                            getOptionLabel={(option) => option.name ?? option}
                                            onChange={(e, n) => {
                                                setSelectedContact( n )
                                            }}
                                            renderInput={(params) => <TextField
                                                                        {...params}
                                                                        variant='standard'
                                                                        label="Kontakt"
                                                                        required
                                                                        helperText={ errors.contact_id }
                                                                        error={ errors.contact_id ? true : false }
                                                                    />}
                                        />
                                        <Stack spacing={0}>
                                            <FormGroup>
                                                <FormControlLabel label="Angebot-Bereich ausblenden" control={
                                                    <Checkbox
                                                        id="hidequote"
                                                        checked={ values.hidequote === 1 ? true : false }
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked === true ? 1 : 0
                                                            setValues(prev =>({
                                                                ...prev,
                                                                hidequote: newValue
                                                            }))
                                                        }}
                                                    />
                                                } />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel label="Bestell-Bereich ausblenden" control={
                                                    <Checkbox
                                                        id="hideorder"
                                                        checked={ values.hideorder === 1 ? true : false }
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked === true ? 1 : 0
                                                            setValues(prev =>({
                                                                ...prev,
                                                                hideorder: newValue
                                                            }))
                                                        }}
                                                    />
                                                } />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel label="Firmen-Feld ausblenden" control={
                                                    <Checkbox
                                                        id="hidecompany"
                                                        checked={ values.hidecompany === 1 ? true : false }
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked === true ? 1 : 0
                                                            setValues(prev =>({
                                                                ...prev,
                                                                hidecompany: newValue
                                                            }))
                                                        }}
                                                    />
                                                } />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel label="Betreuer-Bereich ausblenden" control={
                                                    <Checkbox
                                                        id="hideadvisor"
                                                        checked={ values.hideadvisor === 1 ? true : false }
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked === true ? 1 : 0
                                                            setValues(prev =>({
                                                                ...prev,
                                                                hideadvisor: newValue
                                                            }))
                                                        }}
                                                    />
                                                } />
                                            </FormGroup>
                                        </Stack>
                                    </Stack>
                                    <Stack direction='row' spacing={1}>
                                        <Button variant='contained' type='submit'
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                const res = await handleCheck()
                                                res && handleSave()
                                            }}
                                        >Save</Button>
                                        <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                        { mode==='edit' &&
                                            <>
                                                <Button variant='contained' color="warning"
                                                    onClick={() => {
                                                        handleDelete( values.id )
                                                    }}
                                                >Delete</Button>

                                                <Button
                                                        variant="contained"
                                                        disableElevation
                                                        onClick={(e) => setEditMenuAnchor(e.currentTarget)}
                                                        endIcon={<KeyboardArrowDownIcon />}
                                                >Options</Button>


                                                <Menu
                                                    anchorEl={ editMenuAnchor }
                                                    open={ editMenuAnchor ? true : false }
                                                    onClose={() => setEditMenuAnchor(null)}
                                                >
                                                    <MenuList>
                                                        <MenuItem
                                                            onClick={() => {
                                                                setEditMenuAnchor(null)       //Contextmenü schliessen
                                                                setDuplicateDialogOpen(true)  //Duplicate-Dialog anzeigen
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <FileCopyIcon fontSize='small'/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                Dossier duplizieren
                                                            </ListItemText>
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => {
                                                                setEditMenuAnchor(null)       //Contextmenü schliessen
                                                                window.open('/' + values.mydriveid, '_blank')
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <PreviewIcon fontSize='small'/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                Anzeigen
                                                            </ListItemText>
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </>
                                        }
                                    </Stack>
                                    { mode === 'edit' &&
                                        <Alert severity="warning">
                                            Wenn dieses Dossier gelöscht wird, werden auch alle Blocks dieses Dossiers gelöscht.<br/>
                                            Dies kann nicht rückgängig gemacht werden.
                                        </Alert>
                                    }
                                </Stack>
                            </Paper>
                        </TabPanel>
                        <TabPanel value='blocks'>
                            { blocks &&
                                <div>
                                    <Stack direction='row' sx={{mb: 3}} spacing={1}>
                                        <IconButton
                                            color="primary"
                                            onClick={(e) => setAddBlockMenuAnchor(e.currentTarget)}                                                
                                        ><AddIcon/></IconButton>
                                        <Menu
                                            anchorEl={ addBlockMenuAnchor }
                                            open={ addBlockMenuAnchor ? true : false }
                                            onClose={() => setAddBlockMenuAnchor(null)}
                                        >
                                            <MenuItem
                                                onClick={() =>{
                                                    handleCreateBlock( values.id )
                                                    setAddBlockMenuAnchor(null)
                                                }}
                                            >Leerer Block</MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    setAddBlockMenuAnchor(null)
                                                    setCopyBlockDialogOpen(true)
                                                }}
                                            >Bestehender Block kopieren</MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    setAddBlockMenuAnchor(null)
                                                    setTemplateSelectDialogOpen(true)
                                                }}
                                            >Von Template</MenuItem>
                                        </Menu>
                                        <IconButton 
                                            color="primary"
                                            onClick={() => fetchBlocks(values.id)}
                                        ><RefreshIcon/></IconButton>
                                    </Stack>
                                    <Stack direction='column' spacing={2}>
                                        {blocks.map(block =>
                                            <Card key={block.id}>
                                                <CardHeader
                                                    title={ <Typography variant='h5' color='primary'>{block.title_source}</Typography> }
                                                    subheader={ block.description_source }
                                                    avatar={ <img src={rootPath + block.image_path} alt={block.image_alt} height={"50px"} /> }
                                                    action={ <Stack direction='row'>
                                                        <IconButton
                                                            onClick={async () => {
                                                                await handleMoveBlockUp( block.id )
                                                                await fetchBlocks( values.id )
                                                            }}
                                                        ><KeyboardArrowUpIcon fontSize='small'/></IconButton>
                                                        <IconButton
                                                            onClick={async () => {
                                                                await handleMoveBlockDown( block.id )
                                                                await fetchBlocks( values.id )
                                                            }}
                                                        ><KeyboardArrowDownIcon fontSize='small'/></IconButton>
                                                    </Stack> }
                                                />
                                                <CardActions>
                                                    <IconButton
                                                        color='warning'
                                                        onClick={async () => {
                                                            await deleteBlock( block.id, apikey )
                                                            await fetchBlocks( values.id )
                                                            refetch()
                                                        }}
                                                    ><DeleteIcon fontSize='small'/></IconButton>
                                                     <Tooltip title='Template aus Block erstellen'>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => {
                                                                setBlockForTemplate( block )
                                                            }}
                                                        ><BookmarkAddIcon fontSize='small'/></IconButton>
                                                    </Tooltip>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            handleEditBlock( block.id )
                                                        }}
                                                    ><EditIcon fontSize='small'/></IconButton>


                                                </CardActions>
                                            </Card>
                                        )}
                                    </Stack>
                                </div>
                            }
                            { (!blocks || blocks.length === 0 ) && 
                                <Typography variant='body1'>Noch keine Blocks...</Typography>
                            }
                        </TabPanel>
                    </TabContext>
                </div>
            </Drawer>

            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie das ausgewählten Dossier wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wenn dieses Dossier gelöscht wird, werden auch alle Blocks dieses Dossiers gelöscht.<br/>
                        Dies kann nicht rückgängig gemacht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>

            <DuplicateDossierDialog
                keepMounted
                open={ duplicateDialogOpen }
                onClose={( v ) => {
                    setDuplicateDialogOpen(false)
                    handleDuplicate( v )
                }}
                id={ values.id }
                name={ values.name }
                ordernumber={ values.ordernumber }
                quantity={ String(values.quantity) }
                part={ values.part }
                deliverydate={ values.deliverydate }
            />

            <CopyBlockDialog
                targetDossierId={ Number(values.id) }
                onClose={( answer ) => {
                    setCopyBlockDialogOpen(false)
                    if(answer){
                        fetchBlocks(values.id)
                        refetch()
                    }
                }}
                open={ copyBlockDialogOpen }            
            />

            <CreateTemplateDialog
                block={ blockForTemplate }
                onClose={( answer) => {
                    if(answer){
                        handleCreateTemplate( answer.block, answer.name, answer.info )
                    }
                    setBlockForTemplate( null )
                }}
                open={ blockForTemplate ? true : false }
            />

            <TemplateSelectDialog
                open={ templateSelectDialogOpen }
                onClose={(id) => {
                    setTemplateSelectDialogOpen(false)
                    if(id){
                        setTemplateId(id)
                        setTemplateCompleteDialogOpen(true)
                    }
                }}
            />

            { values.id && templateId &&
                <TemplateCompleteDialog
                apikey= { apikey }
                    targetDossierId = { values.id }
                    templateId = { templateId }
                    open = { templateCompleteDialogOpen }
                    onClose={(result) => {
                        setTemplateCompleteDialogOpen(false)
                        fetchBlocks(values.id)
                        refetch()
                    }}
                    
                />
            }

            { apikey && 
                <EditBlockDialog
                    blockId = { editBlockId }
                    dossierId = { editBlockDossierId }
                    apikey = { apikey }
                    open={ editBlockDialogOpen }
                    onClose={(id) => {
                        setEditBlockDialogOpen(false)
                        fetchBlocks(values.id)
                        refetch()
                    }}
                />
            }

        </Container>
    );
}
 
export default Dossierspage;