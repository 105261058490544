import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Benutzer abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//apikey: Es muss ein gültiger apikey angegeben werden. Es kann der apikey des angemeldeten Benutzers verwendet werden.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
export const fetchUsers = async ( limit, search, apikey) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'users/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    return await coreFetch(uri, 'GET', headers)
}


//Einen Benutzer abfragen mit einer id
//apikey: Es muss ein gültiger apikey angegeben werden. Es kann der apikey des angemeldeten Benutzers verwendet werden.
//id: id des Users der abgefragte werden soll.
export const fetchUser = async ( id, apikey ) => {
    var uri = await getApiPath() + 'users/' + id;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    return await coreFetch(uri, 'GET', headers)
}


//Kontrolle der Logindaten. Wenn username und password übereinstimmen, wird ein User zurückgegeben, ansonten eine Fehlermeldung
//Returns ein JSON mit Status, Results und Message zurückgegeben.
export const login = async ( username, password) => {
    const payload = {
        username: username,
        password: password,
        key:'4FC5-4F40-1155'
    }

    const headers = {
        "Content-Type": "application/json"
    }

    //return await fetch(uri, { method: 'POST', headers: headers })
    // return await coreFetch('https://my-drive.id/api/users/?login', 'POST', headers, JSON.stringify(payload))

    return await coreFetch(await getApiPath() + 'users/?login', 'POST', headers, JSON.stringify(payload))
}


//Einen neuen User erstellen.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function createUser(username, email, password, admin, disabled, apikey){
    const payload = {
        username: username,
        email: email,
        password: password,
        administrator: admin,
        disabled: disabled
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'users/', 'POST', headers, JSON.stringify(payload))
}

//Einen User ändern.
//id:   id des Users der geändert werden soll
//new{x}: die neuen Werte des users
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editUser(id, newusername, newemail, newapikey, newadmin, newdisabled, apikey){
    const payload = {
        username: newusername,
        email: newemail,
        apikey: newapikey,
        administrator: newadmin,
        disabled: newdisabled
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'users/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Einen user löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteUser(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'users/' + id, 'DELETE', headers)
}


//Passwort eines Benutzers ändern.
//id:   id des Users der geändert werden soll
//password: das neue Passwort
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function changePassword(id, password, apikey){
    const payload = {
        password: password,
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'users/' + id + "?password", 'PUT', headers, JSON.stringify(payload))
}



//Gibt an, ob ein username schon existiert.
//username: username die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf der username identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn der username schon existiert, ansonsten false
export async function exitsUser(username, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', username)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'users/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}
