import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Skeleton, Button, Slide, AppBar, Toolbar, IconButton, Dialog, Typography, Alert, Stack, Paper, Box } from '@mui/material'
import { createBlock, editBlock, fetchBlock } from '../includes/dbBlocksFunc'
import SourceTextfield from './SourceTextfield'
import ImageDialog from './ImageDialog'
import EditEntries from './EditEntries'
import { useSnackbar } from 'notistack'
import { checkSource } from '../includes/dbSourcesFunc'
import { getRootPath } from '../includes/coreFunc'


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

  
function EditBlockDialog(props) {
    const { onClose, open, blockId:blockIdProp, dossierId: dossierIdProp, apikey } = props;
    const [ blockId, setBlockId ] = useState(null)
    const [ dossierId, setDossierId ] = useState(null)
    const [ errors, setErrors ] = useState({})
    const [ blockValues, setBlockValues ] = useState(null)
    const [ rootPath, setRootPath ] = useState('')
    const [ imageDialogOpen, setimageDialogOpen ] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        (async () => {
            if (open) {
                setBlockId(blockIdProp)
                setDossierId(dossierIdProp)
                setErrors({})

                if( blockIdProp ){
                    //Block laden:
                    let answer = await fetchBlock( blockIdProp )
                    if (answer && answer.status === 1){
                        setBlockValues( answer.results )
                    }else{
                        setErrors({err: 'Block konnte nicht geladen werden.'})
                    }
                }else if( !blockIdProp && dossierIdProp ){
                    //Es soll ein neuer Block für ein Dossier erstellt werden.
                    setBlockValues( {dossier_id: dossierIdProp, image_id: null, title_id: null, description_id: null, title_source: '', description_source: ''} )
                }
            }
            setRootPath(await getRootPath())
        })()
    }, [open, blockIdProp, dossierIdProp]);



    const handleClose = () => {
        onClose(false);
    };


    /**
     * Die Änderungen des Blocks speichern (nur Block, nicht die Entries)
     */
    const handleSave = async () => {
        //Sources kontrollieren, falls notwendig neu anlegen:
        if(blockValues.title_source){
            let answer = await checkSource(blockValues.title_source, apikey)
            if(answer){
                blockValues.title_id = answer
            }else{
                enqueueSnackbar('Title-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            blockValues.title_id=null
        }
        if(blockValues.description_source){
            let answer = await checkSource(blockValues.description_source, apikey)
            if(answer){
                blockValues.description_id = answer
            }else{
                enqueueSnackbar('Gruppen-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            blockValues.description_id=null
        }

        const answer = await editBlock( blockId, blockValues.dossier_id, blockValues.image_id, blockValues.title_id, blockValues.description_id, apikey )
        if(answer && answer.status === 1 ){
            enqueueSnackbar('Tiptop. Gespeichert.', {variant: 'success'})
        }else{
            enqueueSnackbar('Konnte den Block nicht speichern.', {variant: 'error'})
        }
    }


    /**
     * Ein neuer Block erstellen für die angegebene Dossier Id
     */
    const handleCreate = async () => {

        //Sources kontrollieren, falls notwendig neu anlegen:
        if(blockValues.title_source){
            let answer = await checkSource(blockValues.title_source, apikey)
            if(answer){
                blockValues.title_id = answer
            }else{
                enqueueSnackbar('Title-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            blockValues.title_id=null
        }
        if(blockValues.description_source){
            let answer = await checkSource(blockValues.description_source, apikey)
            if(answer){
                blockValues.description_id = answer
            }else{
                enqueueSnackbar('Beschreibeungs-Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            blockValues.description_id=null
        }

        const answer = await createBlock(dossierId, blockValues.image_id, blockValues.title_id, blockValues.description_id, apikey )

        if(answer && answer.status === 1 ){
            setBlockId( answer.id )
            enqueueSnackbar('Neuer Block wurde angelegt.', {variant: 'success'})
        }else{
            enqueueSnackbar('Konnte den Block nicht erstellen.', {variant: 'error'})
        }        
    }



    /**
     * Kontrolliert ob die Eingaben beim Block ok sind.
     * @returns True wenn kein Fehler, ansonsten False
     */
    const handleCheck = () => {
        let collect = {}

        if(!blockValues.image_id){
            collect.image_id = "Bitte ein Bild auswählen."
        }

        if(!blockValues.title_source){
            collect.title_id = "Titel eingeben."
        }

        if(!blockValues.description_source){
            collect.description_id = "Beschreibung darf nicht leer sein."
        }

        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }

  

    //Beim Laden eines Bilds! Kontrolle der Dimensionen
    const handleImageLoad = ( { target:img } ) => {
        let errmsg = null
        if (img.naturalHeight > 200 || img.naturalWidth > 200){
            errmsg = `Dimensionen sollten nicht grösser als 200x200px sein! Aktuell: ${img.naturalWidth}x${img.naturalHeight}`
        }
        setErrors(prev => ({
            ...prev,
            image_id: errmsg
        }))
    }

    //Ein ImageDialog zur Auswahl eines Betruer-Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            setBlockValues(prev =>({
                ...prev,
                image_id: result.id,
                image_path: result.path,
                image_alt: result.alt
            }))
        }
        setimageDialogOpen(false)
    }


    return (
        <>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Typography sx={{ ml: 2 }} variant="h5" component="div">
                    { blockValues && blockValues.title_source }
                </Typography>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    { blockValues && blockValues.description_source }
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>                
            </Toolbar>
            </AppBar>
            <Box sx={{padding: 2}}>
                { errors.err &&
                    <Alert severity='warning'>
                        {errors.err}
                    </Alert>
                }
                { blockValues &&
                    <Box>
                        <Paper elevation={3} sx={{padding: 3, marginBottom: 2}}>
                            <Stack direction='row'>
                                <Button
                                    onClick={() => {
                                    setimageDialogOpen(true)
                                }}>
                                    { blockValues.image_path ?
                                        <Tooltip
                                            title={
                                                <>
                                                <p>{blockValues.image_name}</p>
                                                <p>{blockValues.image_description}</p>
                                                </>
                                            }
                                        >
                                            <img
                                                onLoad={(e) => handleImageLoad(e)}
                                                src={rootPath + blockValues.image_path}
                                                alt={blockValues.image_alt}
                                                style={{maxHeight: 50, maxWidth: 100}}
                                            />
                                        </Tooltip>
                                    : 
                                        <Skeleton variant="rounded" width={50} height={50} />
                                    }
                                </Button>
                                { errors.image_id && 
                                    <Alert severity='warning'>{errors.image_id}</Alert>
                                }
                            </Stack>

                            <SourceTextfield
                                label="Titel"
                                required={false}
                                fullWidth
                                value={ blockValues.title_source }
                                helperText={ errors.title_id }
                                onChange={(n) => {
                                    setBlockValues(prev =>({
                                        ...prev,
                                        title_source: n
                                    }))
                                }}
                            />
                            <SourceTextfield
                                label="Beschreibung"
                                required={false}
                                fullWidth
                                value={ blockValues.description_source }
                                helperText={ errors.description_id }
                                onChange={(n) => {
                                    setBlockValues(prev =>({
                                        ...prev,
                                        description_source: n
                                    }))
                                }}
                            />
                            <Stack justifyContent='flex-start' alignItems='flex-start' sx={{marginTop: 3}}>
                                { blockId && 
                                    <Button variant='contained' onClick={ ()  => {
                                        if(handleCheck()){handleSave()}
                                    }}>
                                        Save
                                    </Button>
                                }
                                { !blockId && dossierId && 
                                    <Button variant='contained' onClick={ () => {
                                        if( handleCheck() ){
                                            handleCreate()
                                        }
                                    }}>
                                        Create
                                    </Button>
                                }
                            </Stack>
                        </Paper>

                        { blockId && apikey && 
                            <EditEntries blockId={blockId} apikey={apikey} />
                        }

                    </Box>
                }
            </Box>
        </Dialog>

        <ImageDialog
            sx={{zIndex: 1400}}
            keepMounted
            open={ imageDialogOpen }
            onClose={(e) => handleImageDialogClose(e)}
        />

        </>
    );
}

EditBlockDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    blockId: PropTypes.number,
    dossierId: PropTypes.number,
    apikey: PropTypes.string.isRequired
};

export default EditBlockDialog;