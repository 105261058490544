


export async function coreFetch(uri, method, headers, body){
    const data = await fetch(uri,{ method: method, headers: headers, body: body })
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .catch((error) => {
            return {status: 0, message: error.message};
        })
    return data;    
}


export async function singleFetch(uri, method, headers, body){
    const data = await fetch(uri,{ method: method, headers: headers, body: body })
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error("Bad response from server");
            }
            return response.text();
        })
        .catch((error) => {
            return {status: 0, message: error.message};
        })
    return data;
}
