import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Templateentries abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.
//return: ein Object, mit status, message, und den Entries als results
export const fetchTemplateentries = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'templateentries/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Templateentry abfragen mit einer id
//id: id des Templateentry die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Entry als results
export const fetchTemplateentry = async ( id ) => {
    var uri = await getApiPath() + 'templateentries/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Alle Templateentries eines Templates abfragen
//template_id: id des Templates, von dem die Templateentries zurückgegeben werden
//return: ein Object, mit status, message, und den Entries als results
export const fetchTemplateentriesFromTemplate = async ( template_id ) => {
    var uri = await getApiPath() + 'templateentries/?template=' + template_id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuen Templateentry erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben. Ausserdem die id des neuen Datensatzes
export async function createTemplateentry(template_id, group_id, caption_id, content_id, link_id, apikey){
    const payload = {
        template_id: template_id,
        group_id: group_id,
        caption_id: caption_id,
        content_id: content_id,
        link_id: link_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templateentries/', 'POST', headers, JSON.stringify(payload))
}


//Ein Templateentry ändern.
//id:   id des Templateentry das geändert werden soll
//new{x}: die neuen Werte des Templateentry
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editTemplateentry(id, template_id, group_id, caption_id, content_id, link_id, apikey){
    const payload = {
        template_id: template_id,
        group_id: group_id,
        caption_id: caption_id,
        content_id: content_id,
        link_id: link_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templateentries/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Templateentry löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteTemplateentry(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'templateentries/' + id, 'DELETE', headers)
}
