import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Dossiers abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Dossier als results
export const fetchDossiers = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'dossiers/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Dossier abfragen mit einer id
//id: id des Dossier die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Dossier als results
export const fetchDossier = async ( id ) => {
    var uri = await getApiPath() + 'dossiers/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neues Dossier erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createDossier(mydriveid, name, part, ordernumber, quantity, deliverydate, hidedetails, brand_id, contact_id, hidequote, hideorder, hidecompany, hideadvisor, apikey){
    const payload = {
        mydriveid: mydriveid,
        name: name,
        part: part ? part : null,
        deliverydate: deliverydate ? deliverydate : null,
        ordernumber: ordernumber,
        quantity: quantity,
        hidedetails: hidedetails,
        brand_id: brand_id,
        contact_id: contact_id,
        hidequote: hidequote,
        hideorder: hideorder,
        hidecompany: hidecompany,
        hideadvisor: hideadvisor,
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'dossiers/', 'POST', headers, JSON.stringify(payload))
}


//Ein Dossier ändern.
//id:   id des Dossiers die geändert werden soll
//new{x}: die neuen Werte des Dossiers
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editDossier(id, mydriveid, name, part, ordernumber, quantity, deliverydate, hidedetails, brand_id, contact_id, hidequote, hideorder, hidecompany, hideadvisor, apikey){
    const payload = {
        mydriveid: mydriveid,
        name: name,
        part: part ? part : null,        
        deliverydate: deliverydate ? deliverydate : null,
        ordernumber: ordernumber,
        quantity: quantity,
        hidedetails: hidedetails,
        brand_id: brand_id,
        contact_id: contact_id,
        hidequote: hidequote,
        hideorder: hideorder,
        hidecompany: hidecompany,
        hideadvisor: hideadvisor,
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'dossiers/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Dossier löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteDossier(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'dossiers/' + id, 'DELETE', headers)
}


//Gibt an, ob eine mydrive-id schon existiert.
//mydriveid: mydriveid die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf die mydriveid identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn die mydriveid schon existiert, ansonsten false
export async function mydriveidExitst(mydriveid, exclude){
    const headers = {
        "Content-Type": "application/json",
    }

    let uri = await getApiPath() + 'dossiers/?exists=' + mydriveid
    if(exclude){
        uri = uri + '&exclude=' + exclude
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}


//Ein Dossier duplizieren
//id: Die id eines bestehenden Dossiers , welches dupliziert werden soll.
// mydriveid, name, ordernumber, quantity, brand_id: Werte für das neue Dossier. Zumindest mydriveid muss ein eindeutiger Wert zugewiesen werden.
//Es wird eine Objekt mit status und message zurückgegeben, sowei der id des neuen Dossiers.
export async function duplicateDossier(id, mydriveid, name, part, ordernumber, quantity, deliverydate, hidedetails, brand_id, contact_id, hidequote, hideorder, hidecompany, hideadvisor, apikey){
    const payload = {
        mydriveid: mydriveid,
        name: name,
        ordernumber: ordernumber,
        part: part ? part : null,        
        deliverydate: deliverydate ? deliverydate : null,        
        quantity: quantity,
        hidedetails: hidedetails,
        brand_id: brand_id,
        contact_id: contact_id,
        hidequote: hidequote,
        hideorder: hideorder,
        hidecompany: hidecompany,
        hideadvisor: hideadvisor,
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'dossiers/?duplicate=' + id, 'POST', headers, JSON.stringify(payload))
}



export async function getMydriveidDossier(mydriveid, language){
    const params = new URLSearchParams()
    params.append('mydriveid', mydriveid)
    if(language){params.append('language', language)}

    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'dossiers/' +  paramsStr

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)

}