import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/Home'
import Layout from './components/Layout'
import Userspage from './pages/Userspage'
import Loginpage from './pages/Loginpage'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthProvider from './components/AuthProvider'
import LanguageProvider from './components/LanguageProvider'
import ProtectedRoute from './components/ProtectedRoute'
import Languagespage from './pages/Languagespage'
import Sourcespage from './pages/Sourcespage'
import Filespage from './pages/Filespage'
import Brandspage from './pages/Brandspage'
import Dossierspage from './pages/Dossierspage'
// import Blockpage from './pages/Blockpage'
import Mydriveid from './pages/Mydriveid'
import Contactspage from './pages/Contactspage'
import Translationspage from './pages/Translationspage'
import Templatespage from './pages/Templatespage'
import Orphanspage from './pages/Orphanspage'
import Queriespage from './pages/Queries'


const queryClient = new QueryClient()

function App() {

  

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LanguageProvider>
          <Router>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap"
            />
            <div className="App">
              <header className="content">
                <Layout >
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/:mydriveid' element={<Mydriveid />} />
                    <Route path='/login' element={<Loginpage />} />
                    <Route path='/users' element={
                                                      <ProtectedRoute redirect="/login" onlyadmin="/">
                                                        <Userspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/users/:id' element={
                                                      <ProtectedRoute redirect="/login" onlyadmin="/">
                                                        <Userspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/languages' element={
                                                      <ProtectedRoute redirect="/login" onlyadmin="/">
                                                        <Languagespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/languages/:id' element={
                                                      <ProtectedRoute redirect="/login" onlyadmin="/">
                                                        <Languagespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/sources' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Sourcespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/sources/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Sourcespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/translations' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Translationspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/files' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Filespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/files/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Filespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/brands' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Brandspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/brands/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Brandspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/contacts' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Contactspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/contacts/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Contactspage />
                                                      </ProtectedRoute>
                                                    } />                                                    
                    <Route path='/dossiers' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Dossierspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/dossiers/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Dossierspage />
                                                      </ProtectedRoute>
                                                    } />
                    {/* <Route path='/block/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Blockpage />
                                                      </ProtectedRoute>
                                                    } /> */}
                    {/* <Route path='/block' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Blockpage />
                                                      </ProtectedRoute>
                                                    } /> */}
                    <Route path='/templates' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Templatespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/templates/:id' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Templatespage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/orphans' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Orphanspage />
                                                      </ProtectedRoute>
                                                    } />
                    <Route path='/queries' element={
                                                      <ProtectedRoute redirect="/login">
                                                        <Queriespage />
                                                      </ProtectedRoute>
                                                    } />
                  </Routes>
                </Layout>
              </header>
            </div>
          </Router>
          </LanguageProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
