import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import debounce from "lodash.debounce"
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useQuery } from 'react-query'
import { FormGroup, FormControlLabel, InputAdornment, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography, Switch } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { deleteQuery, fetchQueries, fetchQuery } from '../includes/dbQueriesFunc'
import { fetchLanguages } from '../includes/dbLanguagesFunc'



const defaultColumns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'query', headerName: 'Query', width: 400 },
    { field: 'creationtime', headerName: 'Date', width: 200 }
];

const groupedColumns = [
    { field: 'count', headerName: 'Count', width: 100 },   
    { field: 'query', headerName: 'Query', width: 600 }
];


const Queriespage = () => {
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(15)
    const [values, setValues] = useState({})
    const [languages, setLanguages] = useState(null)
    const [group, setGroup] = useState(true)
    const [columns, setColumns] = useState(groupedColumns)
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['queries', apikey, rowsCount, search, group], () => fetchQueries(rowsCount, search, group))
    const searchInput = useRef()


    useEffect(() => {
        (async () => {
            const answer = await fetchLanguages()
            if(answer && answer.status === 1){ setLanguages(answer.results) }
        })();
    // eslint-disable-next-line
    },[])



    /**
     * Eine Query im Drawer anzeigen
     * @param {number} id Die id der Query die im Drawer angezeigt wird.
     */
    const handleShow = async ( id ) => {
        const answer = await fetchQuery(id)
        if(answer && answer.status === 1){
            if(answer.count === 1){
                const query = answer.results
                setValues(query)
                handleOpenDrawer()
            }else{
                enqueueSnackbar('Es existiert keine Query mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }        
    }


    /**
     * Den Drawer öffnen
     */
    const handleOpenDrawer= () => {
        if(!group){
            setIsDrawerOpen(true)
        }
    }

    /**
     * Den Drawer (rechte Seite) schliessen
     */
     const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }
    
    /**
     * Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
     * @param {string} text Suchtext
     */
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }

    /**
     * Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
     */
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])

    /**
     * Einen Datensatz löschen
     * @param {number} id Die id des Query das gelöscht wird.
     * @returns True wenn erfolgreich, ansonsten false
     */
    const handleDelete = async (id) => {
        const result = await deleteQuery(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return false
        }else{
            enqueueSnackbar("Query erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return true
        }
    }

    /**
     * Alle ausgewählten Zeilen löschen
     */
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteQuery(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }



    const handleChangeGroup = async ( value ) => {
        setGroup(value)

        if(value){
            //Gruppieren:
            setColumns(groupedColumns)
        }else{
            //nicht Gruppieren
            setColumns(defaultColumns)
        }
    }


    return (
        <Container>
            <Typography variant="h1">
                Queries
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={ group } onChange={ () => handleChangeGroup( !group )} />} label="Gruppieren" />
                        </FormGroup>
                        { !group && 
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection = { !group }
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => handleShow(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">Query</Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/>
                                <TextField variant="standard" label="Datum" type="text" disabled fullWidth value={ values.creationtime }/>
                                <TextField variant="standard" label="Suchtext" type="text" disabled fullWidth value={ values.query }/>
                                <TextField variant="standard" label="Sprache" type="text" disabled fullWidth
                                    value={(languages &&  languages.find(o => o.id === values.language) ) ? languages.find(o => o.id === values.language).symbol : values.language}
                                />
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Close</Button>
                                <Button variant='contained' color="warning"
                                    onClick={() => {
                                        handleDelete( values.id )
                                    }}
                                >Delete</Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Queries wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Queriespage;