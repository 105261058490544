import { coreFetch } from './dbCoreFunc'
import { getApiPath } from "./coreFunc"


//Alle Blocks abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Block als results
export const fetchBlocks = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = await getApiPath() + 'blocks/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Block abfragen mit einer id
//id: id des Blocks die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Block als results
export const fetchBlock = async ( id ) => {
    var uri = await getApiPath() + 'blocks/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Alle Blocks eines Dossiers abfragen
//dossier_id: id des Dossier, von dem die Blocks zurückgegeben werden
//return: ein Object, mit status, message, und dem Block als results
export const fetchBlocksFromDossier = async ( dossier_id ) => {
    var uri = await getApiPath() + 'blocks/?dossier=' + dossier_id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuen Block erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createBlock(dossier_id, image_id, title_id, description_id, apikey){
    const payload = {
        dossier_id: dossier_id,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'blocks/', 'POST', headers, JSON.stringify(payload))
}


//Ein Block ändern.
//id:   id des Blocks die geändert werden soll
//new{x}: die neuen Werte des Blocks
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editBlock(id, dossier_id, image_id, title_id, description_id, apikey){
    const payload = {
        dossier_id: dossier_id,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'blocks/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Block löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteBlock(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'blocks/' + id, 'DELETE', headers)
}




//Einen Block kopieren, mit Angabe des Ziel-Dossier.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function copyBlock(block_id, targetdossier_id, apikey){
    const payload = {
        block_id: block_id,
        targetdossier_id: targetdossier_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(await getApiPath() + 'blocks/?copy', 'POST', headers, JSON.stringify(payload))
}


export async function moveBlockUp(block_id, apikey){
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }
    return await coreFetch(await getApiPath() + 'blocks/?up=' + block_id, 'POST', headers)
}

export async function moveBlockDown(block_id, apikey){
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }
    return await coreFetch(await getApiPath() + 'blocks/?down=' + block_id, 'POST', headers)
}