import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import TranslateIcon from '@mui/icons-material/Translate'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { FormGroup, FormControlLabel, Checkbox, Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchSource, deleteSource, fetchOrphans} from '../includes/dbSourcesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { fetchLanguages } from '../includes/dbLanguagesFunc'
import { fetchTranslations } from '../includes/dbTranslationsFunc'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'source', headerName: 'Source', width: 300 },
    { field: 'key', headerName: 'Key', width: 200 },
];

const Orphanspage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [values, setValues] = useState({})
    const [translations, setTranslations] = useState({})
    const [languages, setLanguages] = useState(null)
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['orphanes'], () => fetchOrphans())


    useEffect(() => {
        (async () => {
            const answer = await fetchLanguages()
            if(answer && answer.status === 1){ setLanguages(answer.results) }
        })();
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const sourceFetch = await fetchSource(id)
        if(sourceFetch && sourceFetch.status === 1){
            if(sourceFetch.count === 1){
                const source = sourceFetch.results
                const translationFetch = await fetchTranslations(0, source.id)
                const translations = Object.fromEntries(translationFetch.results.map((translation) => [translation.language_id, [translation.translation, translation.id] ]));
                //Also: Translations ist ein Obj. Jede Language hat ein Property. Das Property hat die id der language. Der Wert ist ein Array mit zwei Werten.
                //Erster Wert ist die translation. Zweiter Wert ist die id der translation selber. Diese id gibt es aber nur, wenn eine source bearbeitet wird. Bei einer neuen Source, ist die translation noch nicht gespeichert und kann auch keine id haben.
                //Wenn aber im edit-mode kann es auch sein, dass eine source noch nicht für alle Werte eine translation hat.
                setTranslations(translations)
                setValues(source)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert keine Source mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }

    
    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
        setIsDrawerOpen(true)
    }


    //Den Drawer (rechte Seite) schliessen
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }

 
    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteSource(id, apikey, false)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Source erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }
    }

    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        let err = false
        for(const id of selectionModel){
            const result = await deleteSource(id, apikey, true)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
                err = true
            }
        }
        if(!err){
            enqueueSnackbar("Source(s) erfolgreich gelöscht.", {variant: "success"})
        }
        refetch()
    }


    return (
        <Container>
            <Typography variant="h1">
                Orphans
            </Typography>
            <Typography variant='subtitle1' sx={{mb: 2}}>Ungenutzte Sources (bei denen kein Key angegeben wurde)</Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => handleEdit(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        Source
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/>
                                <TextField
                                    disabled
                                    variant='standard'
                                    label='Source'
                                    id='source'
                                    required
                                    fullWidth
                                    value={ values.source }
                                />
                                <TextField
                                    disabled
                                    variant='standard'
                                    label='Key'
                                    id='key'
                                    fullWidth
                                    value={ values.key ? values.key : '' }
                                />
                                <FormGroup>
                                    <FormControlLabel label="Source benötigt keine Übersetzung" control={
                                        <Checkbox
                                            disabled
                                            id="notranslations"
                                            checked={ values.notranslations === 1 ? true : false }
                                        />
                                    } />
                                </FormGroup>
                                { (values.notranslations === 0 && languages && languages.length > 1) &&
                                    <Stack spacing={2} className="translations">
                                        <Typography variant='h6' sx={{ marginTop: 5 }}>Übersetzungen</Typography>
                                        {languages.map(language => {
                                            if(language.system===0){
                                                return (
                                                    <Stack key={language.id} direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                                                        {language.leo && 
                                                            <a href={`https://dict.leo.org/${language.leo}/${values.source}`} target="_blank" rel="noopener noreferrer">
                                                                <TranslateIcon color='info'/>
                                                            </a>
                                                        }
                                                        <TextField
                                                            disabled
                                                            variant='standard'
                                                            label={language.name}
                                                            fullWidth
                                                            value={ translations[language.id] ? translations[language.id][0] : ""}
                                                        />
                                                    </Stack>
                                                )
                                            }else{
                                                return null
                                            }
                                        })}
                                    </Stack>
                                }
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                <Button variant='contained' color="warning"
                                    onClick={() => {
                                        handleDelete( values.id )
                                    }}
                                >Delete</Button>
                            </Stack>
                            <Alert severity="warning">
                                Wird die Source gelöscht, werden auch alle Übersetzungen gelöscht!<br/>
                                Dies kann nicht rückgängig gemacht werden.<br/>
                                {"Wird eine Source in einem Dossier -> Block -> Entry verwendet, kann sie nicht gelöscht werden."}
                            </Alert>
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Sources wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wird die Source gelöscht, werden auch alle Übersetzungen gelöscht!<br/>
                        Dies kann nicht rückgängig gemacht werden.<br/>
                        {"Wird eine Source in einem Dossier -> Block -> Entry verwendet, kann sie nicht gelöscht werden."}
                    </Alert>
                    <Alert severity="info">
                        Hat eine Source einen Key, kann diese nur einzeln gelöscht werden (im edit Fenster).
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Orphanspage;